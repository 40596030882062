.width-70per-blog {
  margin-left: 135.008px;
}
.firstbox {
  position: "relative";
}
.top-light-blue-box {
  display: flex;
  justify-content: center;
  background-color: #f8f9ff;
  padding: 3% 0 6% 0;
}
.teachze-blog-parent-grid {
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.online-learning-img-blog {
  width: 100%;
  object-fit: contain;
}

.Search-Bg-Rectangle-blog {
  margin: 10px;
  margin-top: 62px;
  transform: skew(-17deg);
  border-radius: 8px;
  width: 620px;
  height: 57.008px;
  padding: 12px;
  box-shadow: 0 2px 4px 0 #d0d6f9;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Search-Rectangle-blog {
  transform: skew(-1deg);
  border-radius: 5.008px;
  width: 44px;
  height: 44px;
  padding: 14px;
  border-radius: 5.008px;
  background-color: #347bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search-Img-blog {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.Search-Btn-blog {
  width: 80px;
  height: 45.008px;
  border-radius: 5.008px !important;
  background-color: #5580ea !important;
  align-self: center;
}

.Search-Text-blog {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: .0838rem !important; */
  color: #fff;
  text-transform: capitalize;
}

.Search-Blogs {
  border: none !important;
  transform: skew(15deg);
  margin-left: 37.008px;
  /* border-bottom: solid .0625rem #bbc4f9 !important; */
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  /* letter-spacing: .04rem; */
  color: #626877;
  width: 200px;
}
.breadkrum {
  margin: 81.008px 100px 17.008px 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: .24px; */
  color: #677788;
}
.imagebelow-text-blog {
  margin-top: 27px;
  font-family: Inter;
  width: 90%;
  font-size: 2.05vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  color: #394052;
}

.image-rep-blog {
  width: 90%;
}
.imageabove-text-blog {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  color: #677788;
}
.listitem_subjectlogooo {
  width: 71px;
  height: 76px;
  object-fit: cover;
}
.listitem_subjectnameoo {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  color: #394052;
  margin-left: 10px;
}
.subject00 {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
  border-radius: 4px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  align-items: center;
  padding: 5%;
  cursor: pointer;
}
.latest-news {
  font-family: Inter;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  color: #394052;
}
.latest-news-test {
  font-family: Inter;
  font-weight: bolder;
  /* letter-spacing:1px; */
  font-size: 1.6vw;
}
.main-box {
  display: flex;
  width: 100%;
  margin-left: 136px;
  margin-right: 100px;
}
.leftbox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rightbox-start {
  font-family: Inter;
  font-size: 1.39vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  /* text-align: justify; */
  color: #394052;
  padding: 15px 0;
}
.about-author {
  font-family: Inter;
  font-size: 1.39vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #677788;
}
.yellow-button-box {
  align-items: center;
  display: flex;
  font-family: Inter;
  margin-top: 20px;
}
.yellow-button {
  color: white;
  width: 113px;
  height: 31px;
  background-color: #fdb515;
  border-radius: 7px;
  border: 0px solid #fdb515;
  cursor: pointer;
  /* letter-spacing: 1px; */
  font-size: 14px;
}
.para {
  width: 90%;
  margin-top: 30px;
  font-family: Inter;
  font-size: 1.05vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  text-align: justify;
  /* text-align: justify; */
  color: #394052;
}
.domainName {
  text-decoration: none !important;
  color: black !important;
  cursor: pointer;
}

.mb-5 {
  margin-bottom: 20px;
}
.point {
  font-family: Inter;
}
.leave-comment {
  margin-top: 7%;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.name {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
  display: flex;
  flex-direction: row;
}
.name-input {
  width: 100%;
  height: 45px;
  outline: none !important;
  outline-width: 0px;
  border-width: 0.8px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgb(207, 206, 206);
}
.Comment-box {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.comment-box-text {
  width: 100%;
  height: 190px;
  outline: none !important;
  outline-width: 0px;
  border-width: 0.8px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgb(207, 206, 206);
}
.send-button {
  width: 150px;
  height: 50px !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;

  margin-top: 20px;
  color: white;
  font-family: Inter;
  transform: skew(-6deg);
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
  font-size: 1.39vw;
}

.email-box-input {
  width: 100%;
  height: 45px;
  outline: none !important;
  outline-width: 0px;
  border-width: 0.8px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgb(207, 206, 206);
}
.email-box {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.name-email-box {
  display: flex;
  flex-direction: row;
}
.vijay-jala {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
}
.vijay-jala-text {
  font-family: Inter;
  font-size: 1.53vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #000;
}
.vijay-jala-image {
  width: 44px;
  height: 44px;
  object-fit: contain;
}
.vijay-author {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.vijay-author-text {
  font-family: Inter;
  font-size: 1.39vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #000;
}
.Line {
  color: black;
  background-color: rgb(24, 22, 22);
  align-items: center;
  width: 90%;
  height: 0.1008px;
  margin: 17px 0 20px 0px;
  border: solid 1px #677788;
}
.astrik {
  color: red;
}
.image_background {
  width: 1437px;
  height: 180px;
  margin: 0 0 42px;
  padding: 61px 131px 65px 135px;
  background-color: #5580ea;
  display: flex;
  flex-direction: row;
}

.textlabel_description {
  display: flex;
  margin-left: 70px !important;
  width: 600px !important;
  height: 58px;
  margin: 13px 283px 0 1px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  color: #fff;
}
.view_button {
  text-align: center;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  width: 217px;
  height: 51px;
  margin-right: 20px !important;
  padding-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  transform: skew(-15deg);
}
.textlabel_booktrial {
  width: 176px;
  height: 29px;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  color: #5580ea;
  margin-top: 40px;
}
.Rectangle-above-image-background {
  width: 100%;
  margin-top: 5%;
  height: 76px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.31);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.twitter {
  height: 50px;
  width: auto;
  margin-left: 1px;
  object-fit: contain;
  margin-top: 6px;
}
.Group-2 {
  height: 50px;
  width: auto;
  margin-left: 8px;
  object-fit: contain;
}
.facebook {
  height: 50px;
  width: auto;
  margin-left: 8px;
  object-fit: contain;
}
.instagram {
  height: 50px;
  width: auto;
  margin-left: 8px;
  object-fit: contain;
}
.width_90 {
  width: 90% !important;
}

@media (max-width: 1279px) {
  .imagebelow-text-blog {
    font-size: 28px;
    margin-top: 20px;
  }
  .para {
    font-size: 18px;
    margin-top: 20px;
  }
  .vijay-jala-text {
    font-size: 20px;
  }
  .vijay-author-text {
    font-size: 18px;
  }
  .rightbox-start {
    font-size: 18px;
    text-align: left;
  }
  .listitem_subjectnameoo {
    font-size: 16px;
  }
  .latest-news-test {
    font-size: 22px;
  }
  .about-author {
    font-size: 20px;
  }
  .send-button {
    font-size: 20px;
  }
}

@media (max-width: 959px) {
  .about-author {
    padding-top: 25px;
  }
  .imagebelow-text-blog {
    width: 100%;
    font-size: 26px;
  }
  .image-rep-blog {
    width: 100%;
  }
  .para {
    width: 100%;
    font-size: 16px;
  }
  .Line {
    width: 100%;
  }
  .width_90 {
    width: 100% !important;
  }
  .vijay-jala-text {
    font-size: 18px;
  }
  .vijay-author-text {
    font-size: 16px;
  }
  .rightbox-start {
    font-size: 16px;
  }
  .listitem_subjectnameoo {
    font-size: 14px;
  }
  .leave-comment {
    font-size: 16px;
  }
  .name {
    font-size: 16px;
  }
  .email-box {
    font-size: 16px;
  }
  .Comment-box {
    font-size: 16px;
  }
}

@media (max-width: 599px) {
  .imagebelow-text-blog {
    font-size: 22px;
  }
  .para {
    font-size: 14px;
  }
  .imageabove-text-blog {
    font-size: 14px;
  }
  .leave-comment {
    font-size: 14px;
  }
  .name {
    font-size: 14px;
  }
  .email-box {
    font-size: 14px;
  }
  .Comment-box {
    font-size: 14px;
  }
  .rightbox-start {
    font-size: 14px;
  }
  .latest-news-test {
    font-size: 20px;
  }
  .send-button {
    height: 40px !important;
    width: 125px;
    font-size: 18px;
  }
  .Rectangle-above-image-background {
    justify-content: center;
    padding-right: 0;
  }
}
