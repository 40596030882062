.crew_view_box {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 8% 10%;
}

.crew_view_box_header {
  font-family: Inter !important;
  font-size: 1.68vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
}

.crew_view_box_details {
  font-family: Inter !important;
  font-size: 1.26vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
  margin-top: 12px !important;
}

.faculty-card {
    padding: 20px 10px;
    border-radius: 3px;
    box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
    background-color: #fff;
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: calc(100% - 32px);
  }

  .faculty-profile-img {
    width: 162px;
    height: 160px;
    padding-bottom: 20px;
  }

  .faculty-profile-avatar {
    border-radius: 50% !important;
    height: 162px !important;
    width: 162px !important;
    margin-bottom: 20px;
  }

  .faculty-profile-detail-avatar {
    border-radius: 10% !important;
    height: 162px !important;
    width: 162px !important;
  }

  .faculty-name {
    font-family: Inter !important;
    font-size: 1.3vw !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #1b2437;
    height: 60px;
  }

  .faculty-role-text {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #98a0a6;
  }

  .img-location {
    width: 13px;
    height: 14px;
    object-fit: contain;
    margin-right: 7px;
  }

  .faculty-location-text  {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #98a0a6;
    padding:20px;
    height: 110px;
  }

  .view-details-btn {
    width: 190px;
    height: 60px;
    border-radius: 5px !important;
    background-color: #5580ea !important;
    text-transform: capitalize !important;
    margin-top: 5% !important;
    -ms-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }

  .view-details-text {
    font-family: Inter !important;
    font-size: 1.15vw !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #fff;
    -ms-transform: skewX(1deg);
    transform: skewX(1deg);
  }

  .display-style {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      margin-bottom: 2% !important;
  }

  .layer {
    position: absolute;
    background-color: #5580ea;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    border-radius: 3px;
    opacity: 0.94;
  }

  .faculty-desc-text {
    padding: 3% 5% !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important; 
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4 !important;
    text-align: center;
    color: #fff;
    align-items: center;
    overflow: auto;
  }

.small-screen-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;
}

.small-screen-justify-center{
  display: flex;
  justify-content: flex-end;
}

.leadership-crew{
  display: flex;
  justify-content: center;
  background: #f8f9ff;
  padding-bottom: 3%;
}

.direction-row {
  display: flex;
  flex-direction: row;
}

.m5 {
  margin: 5% 0;
}

.align-items-center{
  display: flex;
  align-items: center;
}

@media (max-width: 1279px) {
  .crew_view_box_header {
    font-size: 20px !important;
  }
  .crew_view_box_details {
    font-size: 16px !important;
  }
  .faculty-name {
    font-size: 20px !important;
  }
  .faculty-profile-img {
    width: 116px;
    height: 115px;
    padding-bottom: 15px;
  }
  .faculty-profile-avatar {
    height: 115px !important;
    width: 115px !important;
    margin-bottom: 15px;
  }
  .faculty-profile-detail-avatar {
    height: 115px !important;
    width: 115px !important;
  }
  .view-details-btn {
    width: 160px;
    height: 50px;
  }
  .view-details-text {
    font-size: 18px !important;
  }
  .faculty-desc-text {
    font-size: 12px !important;
  }
  .faculty-location-text {
    height: 150px;
  }

}

@media (max-width: 959px) {
  .crew_view_box_header {
    font-size: 16px !important;
  }
  .crew_view_box_details {
    font-size: 14px !important;
  }
  .faculty-location-text {
    height: 120px;
  }
}

@media (max-width: 599px) {
  .crew_view_box_header {
    font-size: 14px !important;
  }
  .crew_view_box_details {
    font-size: 12px !important;
  }
  .faculty-location-text {
    height: auto;
  }
  .small-screen-pb {
    padding-bottom: 5% !important;
  }
}