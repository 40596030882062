
    .trialButton  {
        padding: 0.8rem; 
        font-weight: "bold";
        transform: skewX(-6deg);
        border-radius: 10px;
        width: 217px;
        height: 61px;
        background-color: white;
    }
    
    .footerWrapper {
        justify-content: space-evenly;
        /* width: "90%"; */
        background-color: rgb(238,242,254);
    }
    .innerFooterLeft {        
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    }
    .innerFooterRight {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footerLogo {
        margin-bottom: 25px;
        /* width: 80%; */
        width: 154.7px;
        height: 63px;
    }
    .getInTouchWrapper {
        display: flex;
        align-items: center;
        /* padding-top: 13px; */
    }  
    /* .getInTouchWrapper :first-of-type {
            margin-top: 130px;
        } */
    
    .divider  {
        height: 2;
        color: #969696;
    }
    .reservedRights  {
        background-Color: rgb(238,242,258);
    }



