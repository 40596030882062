@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.landingPageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background: linear-gradient(180deg, #f8f9ff 70%, #fff 30%);
}

.landingPageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5%;
}

.center-text-small-screen {
  margin-top: -35px !important;
}

.attend-now-btn-small {
  width: 190px;
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background: #5580ea !important; */
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  margin-top: 70px !important;
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.attend-now-text-small {
  font-size: 20px !important;
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -ms-transform: skewX(1deg);

  transform: skewX(1deg);
}

.Taking-Forward-The-N {
  max-width: 65%;
  font-family: Inter !important;
  font-size: 3.3vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  /* letter-spacing: 3.56px !important; */
  text-align: center;
  color: #394052;
  align-self: center;
  margin: 0 0 20px 0 !important;
  letter-spacing: 0.00938em !important;
}

.Taking-Forward-The-N .text-style-1 {
  /* letter-spacing: -1.33px !important; */
  color: #6990ed;
  text-decoration: underline;
}

.Taking-Forward-The-N .text-style-2 {
  /* letter-spacing: -1.33px !important; */
  color: #ec9367;
  text-decoration: underline;
}

.textlabel_header-copy {
  font-family: Inter !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72 !important;
  text-align: center;
  color: #394052;
  align-self: center;
  max-width: 59%;
  margin-bottom: 1.875rem !important;
}

.popup {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 35%;
}

.popup img {
  width: 100%;
}

.container {
  position: relative;
}

.toprightClose {
  position: absolute;
  top: 0px;
  right: 3px;
  font-size: 18px;
  cursor: pointer;
  /* height: 30px; */
}

.bottomrightClose {
  position: absolute;
  bottom: 0px;
  right: 3px;
  font-size: 18px;
}

.allRectangles {
  display: flex;
  flex-direction: row;
}

.examRectangle {
  height: 40px;
  border-radius: 5px;
  background-color: #f7dbd4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 20px;
  padding: 0 15px;
}

.tutionRectangle {
  /* width: 83px; */
  height: 40px;
  border-radius: 5px;
  background-color: #dfddef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 18px;
  padding: 0 15px;
}

.testSeriesRectangle {
  /* width: 83px; */
  height: 40px;
  border-radius: 5px;
  background-color: #cfeffe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 20px;
  padding: 0 15px;
}

.blueSquare {
  width: 12px;
  height: 12px;
  margin: 0 7px 0 0;
  border-radius: 2px;
  background-color: #5580ea;
}

.lightBlueSquare {
  width: 12px;
  height: 12px;
  margin: 0 7px;
  border-radius: 2px;
  background-color: #dde7ff;
}

.startLearningWith {
  font-family: Inter !important;
  font-size: 2.375rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54 !important;
  /* letter-spacing: -1.44px !important; */
  text-align: center;
  color: #000;
  margin-top: 50px !important;
}

.getUnlimitedAccess {
  font-family: Inter !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55 !important;
  /* letter-spacing: 2.31px !important; */
  text-align: center;
  color: #6b6588;
  align-self: center;
  margin-bottom: 50px !important;
  margin-top: 20px !important;
}

.Mask {
  width: 90%;
  height: 8rem;
  margin: 0px 0px 30px 0px;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.kHeader {
  font-family: Inter !important;
  font-size: 1.875rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: normal !important; */
  text-align: center;
  color: #333;
}

.examTaken {
  font-family: Inter !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: normal !important; */
  text-align: center;
  color: #333;
}

.MaskBottom {
  width: 90%;
  margin: 20px;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #fff;
}

.animation_gif {
  width: 858px !important;
  height: 520px !important;
  object-fit: cover;
  align-self: center;
  padding: 3% 0 5% 0;
}

.Learn-from-Expert {
  max-width: 464px;
  font-family: Inter !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35 !important;
  /* letter-spacing: 4.01px !important; */
  color: #394052;
  margin: 1.875rem 0 !important;
}

.Learn-from-Expert .text-style-1 {
  text-decoration: underline;
  color: #5580ea;
}

.Discover-a-new-way {
  max-width: 422px;
  font-family: Inter !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94 !important;
  text-align: left;
  /* letter-spacing: 1.85px !important; */
  color: #394052;
}

.grid-padding {
  padding: 40px 100px;
}

.book-img {
  width: 7.188rem;
  height: 7.188rem;
  object-fit: contain;
}

.Competetive-Exam {
  font-family: Inter !important;
  font-size: 1.875rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: 2.23px !important; */
  color: #394052;
}

.grid-padding-2 {
  padding: 0.5rem;
  align-items: center;
}

.developer-activity-img {
  width: 80%;
  object-fit: contain;
}

.justify_flex_end {
  display: flex;
  justify-content: flex-end;
}

.flex_column_justify_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 70px;
  text-align: center;
}

.bgDiv {
  position: absolute;
  background-color: #f8f9ff;
  height: 75%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.subList {
  padding: 1113px 134px 1618px 133px;
  background-color: rgba(238, 240, 255, 0.4);
}

.ulList {
  list-style-type: none;
  margin: 14px 0 14px 0;
  font-family: Inter;
  font-size: 1.05vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: 2.08px; */
  color: #394052;
}

.view_box_content_text_li {
  list-style-type: square;

  font-family: Inter !important;
  font-size: 1.25vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67 !important;
  /* text-align: justify; */
  color: #394052;
}

.view_box_content_text_li:hover {
  color: #5580ea;
}

.book-trial-btn-small {
  /* margin: 1.875rem 0 !important; */

  /* background-color: #5580ea !important; */
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background-color: #5580ea; */
  align-self: center;
  -ms-transform: skewX(-6deg);
  /* // webkit: "skewX(-20deg)"; */
  transform: skewX(-6deg);
  padding: 0 2% !important;
  text-transform: capitalize !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.book-trial-btn-small:hover {
  box-shadow: 5px 10px 10px #afafaf;
}

.ok-btn-small {
  /* margin: 1.875rem 0 !important; */

  /* background-color: #5580ea !important; */
  background-image: linear-gradient(
    87deg,
    #5580ea -15%,
    #5580ea 118%
  ) !important;
  height: 40px;
  text-align: center;
  color: #fff !important;
  background-color: #5580ea;
  align-self: center;
  -ms-transform: skewX(-6deg);
  /* // webkit: "skewX(-20deg)"; */
  transform: skewX(-6deg);
  padding: 3% 2% !important;
  width: 23%;
  margin: 5% 0 !important;
  text-transform: capitalize !important;
}

.book-trial-text-small {
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -ms-transform: skewX(1deg);

  transform: skewX(1deg);
}

.bgBottomDiv {
  position: absolute;
  background-color: #f8f9ff;
  height: 120vh;
  width: 100%;
  bottom: 0;
  z-index: -1;
}

.card {
  margin: 50px 0;
  /* padding-top: 40px; */
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
}

.Free-Trial-Btn {
  width: 150px;
  height: 45px;
  margin: 0 10px 0 0 !important;
  border: 0 !important;
  border-radius: 5px !important;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.Free-Trial-Text {
  /* height: 20px; */
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-transform: capitalize;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
}

.Free-Trial-Btn:hover {
  box-shadow: 5px 10px 10px #afafaf;
}

.Login-Btn {
  width: 134px;
  height: 40px;
  margin: 0 0 0 10px !important;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
}

.Login-Text {
  height: 20px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: normal; */
  color: #fff;
  text-transform: capitalize;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
}

.headerlogo {
  height: 50px !important;
  width: 122.8px !important;
  cursor: pointer;
  /* margin-right: 70px; */
}

.MuiTab-root {
  min-width: 100px !important;
}

.Mui-selected .MuiTab-wrapper {
  color: #5580ea !important;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.MuiTab-wrapper {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #394052;
  text-transform: capitalize;
}

.Start-Learning-with-footer {
  font-family: Inter !important;
  font-size: 40px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* letter-spacing: -0.5px !important; */
  color: #fff;
  margin-bottom: 13px !important;
}

.Discover-a-new-way-footer {
  max-width: 65%;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81 !important;
  /* letter-spacing: 0.15px !important; */
  color: #fff;
}

.BOOK-A-FREE-TRIAL-footer {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61 !important;
  letter-spacing: normal !important;
  color: #5580ea;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
}

.Learn-from-education-footer {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07 !important;
  /* letter-spacing: normal !important; */
  color: #000;
}

.Link-item-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  /* letter-spacing: normal; */
  color: #000 !important;
  margin-top: 25px !important;
}

.Links-header-text {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89 !important;
  /* letter-spacing: normal !important;  */
  color: #000;
}

.get-in-touch-item-text {
  font-family: Inter !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-decoration: none;
  /* letter-spacing: normal !important; */
  color: #000;
}

.All-rights-reserve-text {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1 !important;
  /* letter-spacing: normal !important; */
  text-align: center;
  color: #000;
  padding: 23px 0;
}

.call-email-img {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}

.social-media-img {
  width: 32px;
  height: 32px;
  margin-right: 3px;
  object-fit: contain;
}

.MuiListItem-gutters {
  padding: 0 !important;
}

.kImage {
  width: 3rem !important;
  height: 3rem !important;
  object-fit: contain;
  /* margin: 2rem; */
  margin-left: 20%;
}

.get-in-touch-padding {
  padding: 12px 12px 12px 0 !important;
}

.Committed-to-textlabel {
  font-family: Inter !important;
  font-size: 2.625rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  text-align: center;
  color: #394052;
  padding: 7% 0 2% 0;
}

.Become-a-part-textlabel {
  font-family: Inter !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72 !important;
  text-align: center;
  color: #394052;
}

.textlabel-slider-header {
  font-family: Inter !important;
  font-size: 2rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53 !important;
  color: #394052;
}

.textlabel-slider-text {
  font-family: Inter !important;
  font-size: 1.313rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65 !important;
  color: #394052;
  margin: 1.875rem 0 !important;
}

.slick-slider .slick-dots {
  list-style: none;
  padding-left: 0;
  display: flex !important;
  align-items: center;
}

.slick-slider .slick-dots li {
  position: relative;
  width: 12px;
  height: 12px;
  margin: 0 7px 0 0;
}

/* .slick-slider .slick-dots li::before {
    display:none;
  } */
.header-font {
  font-size: 17px;
}

.slick-slider .slick-dots li::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 12px;
  width: 12px;
  background-color: #dde7ff;
  border-radius: 2px;
  background-color: #dde7ff;
}

.slick-slider .slick-dots li.slick-active::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 12px;
  width: 12px;
  background-color: #5580ea;
  border-radius: 2px;
}

.slick-slider .slick-dots li button {
  /* display: none; */
  height: 12px;
  width: 12px;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.tiltImportant {
  padding: 50px 0 100px 0;
}

.slick-slider.tiltImportant .slick-dots {
  justify-content: flex-end;
  position: absolute !important;
  height: 20px;
  top: 0;
}

.slick-slider.tiltImportant .slick-dots li {
  width: 22.9px;
  height: 20px;
  margin: 0 14px;
}

.slick-slider.tiltImportant .slick-dots li button {
  /* display: none; */
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-slider.tiltImportant .slick-dots li::after {
  width: 22.9px;
  height: 20px;
  border-radius: 3px;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
}

.slick-slider.tiltImportant .slick-dots li.slick-active::after {
  width: 22.9px;
  height: 20px;
  border-radius: 3px;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
}

.landing-carousel {
  padding-top: 5% !important;
}

.slick-slider.landing-carousel .slick-next {
  top: -8px !important;
  height: 30px;
  right: 3% !important;
  width: 34px;
}

.slick-slider.landing-carousel .slick-prev {
  top: -8px !important;
  right: 8% !important;
  left: auto !important;
  height: 30px;
  width: 34px;
}

/* .more-item-list .MuiPopover-paper {
  padding: 1%;
} */
.more-item-list .MuiPaper-root {
  color: #394052;
  padding: 10%;
  margin-top: 10%;
  width: 100px;
  border-radius: 8px;
  display: inline-table;
}

.more-list-item {
  padding: 2% 0 !important;
}

.more_dropdown_arrow {
  height: 10px;
  width: 10px;
  object-fit: contain;
  margin-left: 10px;
}

.more-item-list .MuiMenuItem-root {
  font-family: Inter;
  padding: 10px 0 !important;
}

.more-item-list .MuiMenuItem-root:hover {
  border-bottom: 2px solid #5580ea !important;
  background-color: transparent !important;
}

.select-menu-item {
  background-color: transparent !important;
}

.select-menu-item:hover {
  border-bottom: 2px solid #5580ea !important;
  background-color: transparent !important;
}

.start_learning_now_btn {
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background: #5580ea !important; */
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  padding: 0 15px !important;
  width: fit-content;
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.start_learning_now_btn_text {
  font-family: Inter !important;
  font-size: 20px !important;
  -ms-transform: skewX(1deg);
  transform: skewX(1deg);
  text-transform: capitalize;
}

.js-scroll {
  /* opacity: 0; */
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.fade-in {
  animation: fade-in 5s ease-in-out both;
}

.scrolled.fade-in-bottom {
  animation: fade-in-bottom 5s ease forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-11 23:32:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Remove inline style */

.landingPageHeaderBody {
  display: flex;
  position: relative;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.Talk {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.right-header {
  display: flex;
  align-items: center;
}
.get-in-touch-item-text p {
  margin: 0;
}

.relative {
  position: relative;
}
.pb-5 {
  padding-bottom: 5%;
}

.Unleash-outer {
  position: relative;
  background: #f8f9ff;
}
.Potential-content {
  display: flex;
  justify-content: center;
  padding-bottom: 4%;
}
.startLearningWith span {
  color: #9281e3;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* Responsive Media Query*/

@media (max-width: 1279px) {
  .Taking-Forward-The-N {
    font-size: 40px !important;
  }

  .textlabel_header-copy {
    max-width: 70%;
    font-size: 20px !important;
  }

  .book-img {
    width: 115px;
    height: 115px;
  }

  .bgDiv {
    height: 60%;
  }

  .headerlogo {
    height: 50px !important;
    width: 110px !important;
    margin-right: 20px;
  }

  .Free-Trial-Btn {
    width: 100px !important;
  }

  .Login-Btn {
    width: 98px !important;
  }

  .MuiTab-root {
    min-width: 80px !important;
  }

  .Free-Trial-Text {
    font-size: 12px;
  }

  .Login-Text {
    font-size: 11px;
  }

  .kImage {
    width: 50px !important;
    height: 50px !important;
  }

  .kHeader {
    font-size: 28px !important;
  }

  .examTaken {
    font-size: 14px !important;
  }

  .call-email-img {
    width: 35px;
    height: 35px;
  }

  .get-in-touch-item-text {
    font-size: 12px !important;
  }

  .social-media-img {
    width: 25px;
    height: 25px;
    margin-right: 0px;
  }

  .get-in-touch-padding {
    padding: 12px 5px 12px 0 !important;
  }

  .Competetive-Exam {
    font-size: 22px !important;
  }

  .Learn-from-Expert {
    font-size: 32px !important;
  }

  .startLearningWith {
    font-size: 42px !important;
  }

  .Discover-a-new-way {
    font-size: 12px !important;
  }

  .Committed-to-textlabel {
    font-size: 32px !important;
  }

  .textlabel-slider-header {
    font-size: 26px !important;
  }

  .textlabel-slider-text {
    font-size: 18px !important;
    margin: 20px 0 !important;
  }

  .startLearningWith {
    font-size: 32px !important;
    margin-top: 1.875rem !important;
  }

  .view_box_content_text_li {
    font-size: 18px !important;
  }

  .ulList {
    font-size: 14px !important;
  }

  .md-width-update {
    width: 85% !important;
  }

  .start_learning_now_btn {
    height: 55px;
  }
}

@media (max-width: 959px) {
  .Taking-Forward-The-N {
    font-size: 34px !important;
    max-width: 90%;
  }

  .popup {
    height: 53%;
    width: 50%;
  }

  .textlabel_header-copy {
    max-width: 75%;
    font-size: 16px !important;
  }

  .animation_gif {
    width: 570px !important;
    height: 370px !important;
  }

  .grid-padding {
    padding: 1.875rem 50px;
  }

  .Learn-from-Expert {
    font-size: 32px !important;
  }

  .startLearningWith {
    /* font-size: 34px !important; */
    margin-top: 1.875rem !important;
  }

  .kHeader {
    /* font-size: 36px !important; */
    margin-left: 8px !important;
  }

  .examTaken {
    /* font-size: 14px !important; */
    margin-left: 8px !important;
  }

  .center-text-small-screen {
    text-align: center;
    padding: 6% 10% 15% 6% !important;
  }

  .Learn-from-Expert {
    max-width: 100%;
  }

  .Discover-a-new-way {
    max-width: 100%;
    font-size: 10px;
    text-align: center;
  }

  .attend-now-btn-small {
    margin: 1.875rem 0 !important;
  }

  .justify-content-center-small-screen {
    justify-content: center !important;
  }

  .book-a-free-trial-small-btn {
    width: 150px !important;
  }

  .Start-Learning-with-footer {
    font-size: 32px !important;
  }

  .BOOK-A-FREE-TRIAL-footer {
    font-size: 12px !important;
  }

  .slick-slider .slick-dots {
    display: block !important;
  }

  .slick-slider.tiltImportant .slick-dots {
    display: flex !important;
  }

  .start_learning_now_btn {
    height: 50px;
    padding: 0 10px !important;
    align-items: center;
  }

  .start_learning_now_btn_text {
    font-size: 20px !important;
  }

  .top-spacing {
    margin-left: -14% !important;
  }

  .md-width-update {
    width: 70% !important;
  }

  .developer-activity-img {
    width: 100%;
  }

  .textlabel-slider-header {
    font-size: 22px !important;
  }

  .textlabel-slider-text {
    font-size: 14px !important;
    margin: 10px 0 !important;
  }
}

@media (max-width: 969px) {
  .landing-carousel {
    padding-top: 18% !important;
  }

  .slick-slider.landing-carousel .slick-next {
    top: 37px !important;
    right: 7% !important;
  }

  .slick-slider.landing-carousel .slick-prev {
    top: 37px !important;
    right: 18% !important;
  }
}

@media (max-width: 599px) {
  .Taking-Forward-The-N {
    font-size: 28px !important;
  }

  .popup {
    height: 41%;
    width: 50%;
  }

  .start_learning_now_btn {
    width: 190px;
  }

  .start_learning_now_btn_text {
    font-size: 17px !important;
  }

  .animation_gif {
    width: 290px !important;
    height: 270px !important;
    padding: 0;
  }

  .grid-padding {
    padding: 16px;
  }

  .Learn-from-Expert {
    font-size: 24px !important;
    margin: 0 0 20px 0 !important;
  }

  .book-img {
    width: 75px;
    height: 75px;
  }

  .Competetive-Exam {
    font-size: 18px !important;
    display: flex;
    justify-content: center;
  }

  .grid-padding-2 {
    align-items: center;
    padding: 14px;
    flex-direction: column;
  }

  .examRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .allRectangles {
    justify-content: space-between;
  }

  /* .allRectangles {
    display: flex;
    flex-direction: column;
    width: 170px;
  } */

  .tutionRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .testSeriesRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bgDiv {
    height: 55%;
  }

  .startLearningWith {
    font-size: 26px !important;
  }

  .kHeader {
    font-size: 28px !important;
  }

  .examTaken {
    font-size: 18px !important;
  }

  .bgBottomDiv {
    height: 170vh;
  }

  .headerlogo {
    padding-left: 25px;
  }

  .Start-Learning-with-footer {
    font-size: 32px !important;
  }

  .Discover-a-new-way-footer {
    max-width: 85%;
    font-size: 14px !important;
    padding-bottom: 20px;
  }

  .textlabel_header-copy {
    max-width: 75%;
    font-size: 12px !important;
  }

  .Committed-to-textlabel {
    font-size: 24px !important;
  }

  .Become-a-part-textlabel {
    font-size: 16px !important;
  }

  .textlabel-slider-header {
    font-size: 24px !important;
  }

  .textlabel-slider-text {
    font-size: 14px !important;
    margin: 10px 0 !important;
  }

  .top-spacing {
    margin-left: -25px !important;
  }

  .md-width-update {
    width: 90% !important;
  }
}

@media (max-width: 469px) {
  .examRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup {
    height: 45%;
    width: 70%;
  }

  .start_learning_now_btn {
    width: 190px;
  }

  .start_learning_now_btn_text {
    font-size: 17px !important;
  }

  .tutionRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .testSeriesRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .textlabel_header-copy {
    max-width: 75%;
    font-size: 12px !important;
  }

  .headerlogo {
    height: 1.875rem !important;
    width: 60px !important;
    padding-left: 20px;
  }

  .Free-Trial-Btn {
    width: 80px !important;
    height: 35px !important;
    margin: 0 5px 0 0 !important;
  }

  .Login-Btn {
    width: 80px !important;
    height: 35px !important;
    margin: 0 !important;
  }

  .Free-Trial-Text {
    font-size: 12px;
  }

  .Login-Text {
    font-size: 9px;
  }

  .top-spacing {
    top: 15px !important;
  }

  .MuiButton-outlined {
    padding: 5px !important;
  }

  .book-trial-text-small {
    font-size: 17px !important;
  }

  .examTaken {
    font-size: 16px !important;
  }

  .get-in-touch-padding {
    padding: 12px 1px 12px 0 !important;
  }
}
