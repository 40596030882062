.Lets-Competetive-Ex {
    font-family: Inter !important;
    font-size: 3.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69 !important;
    color: #394052;
    letter-spacing: 0.00938em !important;
  }
 .selectclass{
    margin: 10px 10px 10px 10px !important;
    font-size: large !important;
    
    color: #5e636d !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;


  }
  .selectclass:hover{
    color: #5580ea !important;

  }
  .class6{
    transform: skewX(6deg) !important;

  }
  .selectclassmainbox{
    background-color: #ecefff;
     margin-left:1px!important ;
     border-radius: 5px;
    cursor: pointer;
    text-transform: capitalize;
    height: 50;
    font-weight: 600!important;
    font-size: 20px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

  }
  .main-box-for-class-and-subject{
    display: flex;
    flex-direction: row!important;
    padding: 20px 0px 5px ;
    
    align-items: center;
    justify-content: left!important;

  }
  

  .Lets-Competetive-Ex-student-centric{
    font-family: Inter !important;
    font-size: 3.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.30 !important;
    letter-spacing: 0 !important;
    color: #394052;
  }
  .Lets-Competetive-Ex .text-style-1 {
    color: #5580ea;
    text-decoration: underline;
  }
  .textlabel_description_1 {
    color: #5a606e;
    font-family: Inter !important;
    font-size: 1.53vw !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 1.73 !important;
    font-style: normal;
    font-stretch: normal;
    margin: 2% 0px 5% 0px !important;    
  }
  .ulList_2 {
    list-style-type: none;
  margin: 14px 0 14px 0;
  font-family: Inter;
  font-size: 1.05vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.view_box_content_text_li_2 {
  list-style-type: square;
  font-family: Inter !important;
  font-size: 1.25vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67 !important;
  color: #394052;
}
.view_box_content_text_li_2:hover {
  color: #5580ea;
}


  .online-learning-img{
    width: 100%;
    object-fit: contain;
  }

  .Search-Bg-Rectangle {
    width: 400px;
    height: 50px;
    padding: 12px;
    box-shadow: 0 2px 4px 0 #d0d6f9;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }

  .Search-Rectangle {
    width: 20px;
    height: 20px;
    padding: 14px;
    border-radius: 5px;
    background-color: #f1f6ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Search-Img {
    width: 22px;
    height: 22px;
    object-fit: contain;
  }

.Search-Btn {
  width: 80px;
  height: 45px;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  align-self: center;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
}

.Search-Text {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #fff;
    text-transform: capitalize;
    -ms-transform: skewX(6deg);
    transform: skewX(6deg);
  }

  .Search-for-course {
    border: none !important;
    border-bottom: solid 1px #bbc4f9 !important;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    color: #394052;
    width: 240px;
  }

  .Courses {
    font-family: Inter !important;
    font-size: 2.64vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92 !important;
    color: #394052;
    margin: 15% 0 !important;
  }

  .Courses-Rectangle {
    padding: 5% 8%;
    border-radius: 5px;
    box-shadow: 0 2px 11px 5px rgba(225, 229, 255, 0.3);
    background-color: #fff;
    margin-right: 12%;
    
  }

  .Courses-Text {
    font-family: Inter !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
    padding: 28px 0;
    cursor: pointer;
  }

.Courses-img {
  width: 30px !important;
  height: 30px !important;
  margin: 0 23px 0 0;
  object-fit: contain;
}

.Selected-Rectangle {
  height: 50px; 
  border-radius: 5px;
  background-color: #5580ea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 38px;
}

.Selected-Text {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #fff;
}

.Unselected-Rectangle {
  height: 50px; 
  border-radius: 5px;
  background-color: #ecefff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  padding: 0 38px;
}

.Unselected-Text  {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #5580ea;
}

.Courses-Header {
  margin: 4% 0 1% 0 !important;
  font-family: Inter !important;
  font-size: 1.94vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07 !important;
  color: #394052;
  cursor: pointer;
}

.Courses-Details {
  border-radius: 20px;
  border: solid 3px #f1f6ff;
  background-color: #fff;
  cursor: pointer;
}

.Course-Name {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67 !important;
  color: #394052;
  text-align: left;
  margin-top: 21px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  height: 80px;
}

.Know-More-Text{
  font-family: Inter !important;
  margin-top:8px !important;
  font-size: 16px !important; 
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
  text-decoration: underline;
  display: flex;
  text-align: center;
}

.Course-Highlights-card{
  margin: 0 0 50px 0;
  padding: 4%;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
}

.Course-Highlights {
  font-family: Inter !important;
  font-size: 2.64vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
}

.Get-assistance-text {
  font-family: Inter !important;
  font-size: 1.53vw !important;
  font-weight: 550 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  color: #394052be;

}

.About-NEET-Text {
  font-family: Inter !important;
  font-size: 2.5vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
}

.National-Eligibility-Text {
  font-family: Inter !important; 
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83 !important;
  color: #495166;
  margin: 15px 0 !important;
} 
.text-style-10 {
  font-family: Inter !important; 
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83 !important;
  margin: 15px 0 !important;
  font-weight: bold;
  color: #5580ea;
  cursor: pointer;
  text-decoration-line: underline;
  
}

.Teachze-is-a-pioneer-text {
  font-family: Inter !important;
  font-size: 32px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47 !important;
  text-align: center;
  color: #394052;
  margin: 100px 100px 60px 100px !important;
}

.spacing-100{
  padding: 4% 0
}


.This-platform-will-text {
  font-family: Inter !important; 
  font-size: 1.53vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  color: #394052;
  margin: 29px 0 !important;
}

.quote-img{
    width: 81.6px;
    height: 60px;
    object-fit: contain;
    position: absolute;
    z-index: -1;
}

.blueTiltSquare{
  width: 22.9px;
  height: 20px;
  margin: 0 14px 0 0;
  border-radius: 3px;
  background-color: #5580ea;
  -ms-transform: skewX(-6deg);
  transform: skewX(-6deg);
}

.lightBlueTiltSquare{
    width: 22.9px;
    height: 20px;
    margin: 0 14px;
    border-radius: 3px;
    background-color: #dde7ff;
    -ms-transform: skewX(-6deg);
    transform: skewX(-6deg);
  }

  .view_box {
    border-radius: 16px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    padding: 50px 25px 50px 25px;
    margin: 10px !important;
    cursor: pointer;
  }

  .view_img{
    width: 85px;
    height: 85px;
    object-fit: contain;
  }

  .view_line {
    margin: 27px 0;
    border: solid 1px #efefef;
  }

  .view_text {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59 !important;
    color: #000;
    height:40
  }

  .view_name {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #000;
  }

  .view_parent {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #677788;
    margin-top: 8px !important;
  }
  
  .bitmap-img{
    width: 100%;
  }

  .Hear-from-text {
    font-family: Inter !important;
    font-size: 2.64vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92 !important;
    color: #394052;
    padding-left: 45px;
  }
  .paddingRight20{
    padding-right: 20px;
  }

  .relative {
    position: relative;
  }
.root-outer {
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
  
}
.Competitive-container{
  display: flex;
  justify-content: center;
  background: #f8f9ff;
  padding-bottom: 2%;
}
.Competetive-info-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  
}

.justifyContent-center {
  display: flex;
  justify-content: center;
}

.react-reveal .xs-4{
  flex-basis: 0;
  align-self: center;
}

.xs-4{
  flex-basis: 0 !important;
  align-self: center !important;
}

.tabslist {
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  padding: 0 38px;
  margin: 3px;
  text-transform: capitalize;
  cursor: pointer;
  transform: skewX(-6deg);

}
.tabslist .innertabs {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  transform:skewX(6deg);
}

.Course-Highlights-card .faculty-info{

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: #464c5d;
  margin-top: 7%;
}
.image-course {
  width: 100%;
  object-fit: contain;
}
.less-margin-small-screen{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 50px 25px 10px;

}
.Arrows{
  width: 34px;
  height: 30px;
  object-fit: contain;
}
.col50{
 display: flex;
 justify-content: center;
 flex-direction: column;
 padding-left: 10px;
 height: 50px;
}

.img-source{
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.direction-row {
  display: flex;
  flex-direction: row;
}

.direction-column {
  display: flex;
  flex-direction: column;
}

.footer-success {
  display: flex;
  justify-content: space-between;
  background-size: contain;
  width: 100%;
}
.course-highlight{
  display: flex;
    justify-content: center;
    background: linear-gradient(rgb(255, 255, 255) 55%, rgb(248, 249, 255) 45%);
    padding-bottom: 0px;

}

.image-faculty {
  height: 52px;
  width: 52px;
  object-fit: contain;
}

.Competitive-container-info{
  display: flex;
  background: #f8f9ff;
  justify-content: center;
}
.start-learning {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  text-align: center;
  text-transform: capitalize;
  transform: skewX(1deg);
}



.small-title {
  font-family: Inter;
  font-size: 1.53vw;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
 width: 90%;
 color: #394052;
}
.small-title1{
 font-size: 16px;
 line-height: 1.49;
 color: #6b6f79;
 margin-top: 20px;
}
.pl{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15%;
}
.dis-jcenter{
  display: flex;
  justify-content: center;
}
.ptop3{
  padding-top: 3%;;
}
.item50 {
  width: 52px;
  height: 52px;
  object-fit: contain;
}
.text-left {
  text-align: left !important;
}

.cascade-Name {
  font-family: Inter;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  color: #394052;
  margin-bottom: 22px !important;

}

.secondary-tabs{
  background: #5580ea;
  color: #fff;
  width: 30px;
  height: 30px;
  transform: skewX(-6deg);
  border-radius: 5px;
  margin: 0 5px;
  align-self: center;
  opacity: 1;
}

.filteredTabsList {
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 38px;
  margin: 3px;
  cursor: pointer;
  text-transform: capitalize;
  transform: skewX(-6deg);

}

.filteredTabsList .innertabs {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  transform:skewX(6deg);
}


  @media (max-width: 1279px){
    .Lets-Competetive-Ex{
      font-size: 40px !important;
    }
    .Lets-Competetive-Ex-student-centric {
      font-size: 40px !important;
    }
    .view_img{
      width: 71px;
    }
    .view_name{
      font-size: 20px !important;
      padding-left: 10px;
    }
    .view_parent{
      font-size: 20px !important;
      padding-left: 10px;
    }
    .less-margin-small-screen{
      margin: 20px 30px 25px 10px !important;
      padding-top: 10px;
    }
    .About-NEET-Text {
      font-size: 30px !important;
    }
    .This-platform-will-text {
      font-size: 20px !important;
    }
    .view_box_content_text_li_2 {
      font-size: 18px !important;
    }
    .ulList_2 {
      font-size: 14px !important;
    }
    .Courses-img {
      margin: 0 8px 0 0;
    }
    .Hear-from-text {
      font-size: 30px !important;
    }
    .textlabel_description_1{
      font-size: 20px;
    }
    .Courses{
      font-size: 32px !important;
    }
    .Course-Highlights {
      font-size: 32px !important;
    }
    .view_text {
      font-size: 12px !important;
    }
    .Get-assistance-text {
      font-size: 20px !important;
    }
    .md-font-20 {
      font-size: 20px !important;
    }
    .Course-Name {
      font-size: 16px !important;
      margin-top: 12px !important;
    }
    .Know-More-Text {
      font-size: 14px !important;
      margin-top: 0 !important;
      align-items: center;
    }
    .md-font-18 {
      font-size: 18px !important;
    }
    .Courses-Header {
      font-size: 24px !important;
    }
    .National-Eligibility-Text {
      font-size: 16px !important;
    }
    .text-style-10 {
      font-size: 16px !important;
    }
  }

  @media (max-width: 959px){
    .Lets-Competetive-Ex{
      font-size: 34px !important;
    }
    .Lets-Competetive-Ex-student-centric {
      font-size: 32px !important;
    }
    .textlabel_description_1 {
      font-size: 18px !important;
    }
    .bitmap-img{
      padding-top: 20px;
      object-fit: contain;
      width: 70%;
    }
    .padding-right {
      padding-right: 30px;
    }
    .Teachze-is-a-pioneer-text{
      margin: 60px !important;
    }
    .Hear-from-text{
      font-size: 28px !important;
    }
    .quote-img{
      width: 62.6px;
      height: 44px;
    }
    .Courses-Rectangle{
      margin-bottom: 36px;
      margin-right: 10px;
    }
    .Get-assistance-text{
      width: 95%;
    }
    .less-margin-small-screen{
      margin: 20px 30px 25px 10px !important;
      padding-top: 0px;  
    }
    .About-NEET-Text {
      font-size: 28px !important;
    }
    .This-platform-will-text {
      font-size: 16px !important;
    }
    .Courses{
      font-size: 28px !important;
    }
    .Course-Highlights {
      font-size: 28px !important;
    }
    .bitmap-img-align {
      display: flex;
      justify-content: center;
    }
    .sm-font-18 {
      font-size: 18px !important;
    }
   
  }

@media (max-width: 599px){
  .padding-right {
    padding-right: 20px;
  }
  .Search-Bg-Rectangle{
    width: 320px;
  }
  .Search-for-course{
    width: 200px;
  }
  .Search-Text{
    font-size: 14px !important;
  }
  .Lets-Competetive-Ex{
    font-size: 30px !important;
  }
  .Lets-Competetive-Ex-student-centric {
    font-size: 30px !important;
  }
  .Teachze-is-a-pioneer-text{
    margin: 30px !important;
    font-size: 24px !important;
  }
  .About-NEET-Text {
    font-size: 24px !important;
  }
  .Courses{
    font-size: 30px !important;
    margin: 20px !important;
  }
  .Course-Highlights {
    font-size: 26px !important;
  }
  .Course-Highlights-card{
    margin: 0;
    padding: 20px;
  }
  .Hear-from-text{
    font-size: 26px !important;
  }
  .quote-img{
    width: 70px;
    height: 36px;
  }

  .view_box {
    padding: 20px;
  }
  .paddingRight20{
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .xs-font-16 {
    font-size: 16px !important;
  }
  .xs-font-14 {
    font-size: 14px !important;
  }
  .Get-assistance-text {
    font-size: 18px !important;
  }
  .Courses-Text {
    padding: 23px 0;
  }
}

@media (max-width: 399px){
  .Search-Bg-Rectangle{
    width: 260px;
  }
  .Search-for-course{
    width: 128px;
    font-size: 14px;
  }
    
  .Search-Btn{
    height: 50px;
    margin-left: 10px !important
  }
    
  .Search-Rectangle {
    width: 22px;
    height: 22px;
    padding: 10px;
  }
  .less-margin-small-screen{
    margin: 20px 50px 25px 10px !important;
    padding-top: 0px;  
  }
}