  .container {
    position: relative;
    
  }
  
  
  .topright {
    position: absolute;
    top: .5rem;
    left: 1rem;
    width: 7.0625rem;
  height: 1.9375rem;
  padding: .0625rem .625rem .1875rem .6875rem;
  border-radius: .25rem;
  background-color: #fdb515;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  text-align: right;
  color: #fff;

  }

    .Search-Bg-Rectangle_blog {
        height: 1.8rem;
        padding: .75rem;
        box-shadow: 0 .125rem .25rem 0 #d0d6f9;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        -ms-transform: skewX(-6deg);
        transform: skewX(-6deg);
        margin-top: -1.5625rem;
        margin-bottom: 1.25rem;
      }
      .search-Btn-icon {
        width: 1.5625rem;
        height: 1.875rem;
        margin: 0 0 0 .625rem!important;
        border-radius: .3125rem !important;
        background-color: #5580ea !important;
      }
      .Search-Img-icon {
        width: 1.375rem;
        height: 1.375rem;
        object-fit: contain;
        color: white;
      }
      .Search-Btn-Blog {
        width: 5rem;
        height: 2.8125rem;
        border-radius: .3125rem !important;
        background-color: #5580ea !important;
        align-self: center;
      }
      .Search-Text-Blog {
        font-family: Inter !important;
        font-size: .875rem !important;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal !important;
        color: #fff;
        text-transform: capitalize;
      }
      .Search-for-course_blog{
        border: none !important;
        font-family: Inter;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.67;
        color: #394052;
        width: 100%;
        height: 1.875rem;
        transform: skewX(1deg);
      }
  
  
    .feature_title {
      font-family: Inter !important;
      font-size: 1.7vw !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal !important;
      text-align: center;
      color: #394052;
      padding-bottom: 1.125rem;
      height: 3.125rem;
    }
  
    .container-8-md{
      border-radius: .125rem;
      box-shadow: 0 0 .8125rem .1875rem rgba(97, 116, 169, 0.05);
      background-color: #fff;
      width: 25rem!important;
      height:37.5rem!important;
      
    }
    .batch-view-box-blog {
      border-radius: 4px;
      box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
      background-color: #fff;
      height: 100%;
      cursor: pointer;
    }
    .batch-view-box-blog-content {
        padding: 0rem 1.25rem 1.25rem 1.25rem;
      }
  
      .textlabel_batches_blog {
        font-family: Inter !important;
        font-size: 1.95vw !important;
        font-weight: bold !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71 !important;
        color: #394052;
      }

  .author_name {
  margin: 0 0 .3812rem !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  color: #000 !important;
  }
  .author {
    margin: .0063rem 0 0 !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    color: #000 !important;
  }
    .textlabel_blog {
      font-family: Inter !important;
      font-size: 1.25vw !important;
      font-weight: bold !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32 !important;
      color: #394052;
      margin: 1.25rem 0 !important;
    }
  
    .textlabel_subtitle_blog {
      font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.93 !important;
  color: #77838f !important;
    }
  
    .view_viewallbox {
      width: 14vw;
      height: 4.2vw;
      border-radius: .3125rem !important;
      background-color: #5580ea !important;
      -ms-transform: skewX(-6deg);
      transform: skewX(-6deg);
    }
  
    .textlabel_viewall {
      font-family: Inter !important;
      font-size: 1.4vw !important;
      font-weight: normal !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal !important;
      text-align: center;
      color: #fff;
      -ms-transform: skewX(1deg);
      transform: skewX(1deg);
      text-transform: capitalize;
    }
  
  .view_text_desc {
    font-family: Inter !important;
    font-size: 1.5vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59 !important;
    color: #000;
  }


.heading-blog {
  display: flex;
  justify-content: center;
  background: f8f9ff;
  padding: 3% 0 6% 0;
}

.pl{
  width: 100%;
  display: flex;
  flex-direction: column;
  
}
.dis-jcenter{
  display: flex;
  justify-content: center;
}

.direction-row {
  display: flex;
  flex-direction: row;
}
.direction-column {
  display: flex;
  flex-direction: column;
}
.image-course {
  width: 100%;
  object-fit: contain;
}
  
@media (max-width: 1279px){
  .textlabel_blog {
    font-size: 16px !important;
  }
  .textlabel_subtitle_blog {
    font-size: 12px !important;
  }
  .textlabel_batches_blog {
    font-size: 26px !important;
  }
  .textlabel_viewall {
    font-size: 18px !important;
  }
}

@media (max-width: 959px) {
  .textlabel_blog {
    font-size: 14px !important;
    margin: 12px 0 !important;
  }
  .topright {
    width: 6rem;
  } 
}


@media (max-width: 599px) {
  .textlabel_batches_blog {
    font-size: 24px !important;
    padding-top: 5%;
  }
  .Search-Bg-Rectangle_blog {
    margin-top: -0.5625rem;
  }
  .view_viewallbox {
    height: 35px !important;
    width: fit-content;
    padding: 0 10px !important;
  }
}
