@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.landingPageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background: linear-gradient(180deg, #f8f9ff 70%, #fff 30%);
}

.landingPageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5%;
}

.center-text-small-screen {
  margin-top: -35px !important;
}

.attend-now-btn-small {
  width: 190px;
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background: #5580ea !important; */
  transform: skewX(-6deg);
  margin-top: 70px !important;
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.attend-now-text-small {
  font-size: 20px !important;
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  transform: skewX(1deg);
}

.Taking-Forward-The-N {
  max-width: 65%;
  font-family: Inter !important;
  font-size: 3.3vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  /* letter-spacing: 3.56px !important; */
  text-align: center;
  color: #394052;
  align-self: center;
  margin: 0 0 20px 0 !important;
  letter-spacing: 0.00938em !important;
}

.Taking-Forward-The-N .text-style-1 {
  /* letter-spacing: -1.33px !important; */
  color: #6990ed;
  text-decoration: underline;
}

.Taking-Forward-The-N .text-style-2 {
  /* letter-spacing: -1.33px !important; */
  color: #ec9367;
  text-decoration: underline;
}

.textlabel_header-copy {
  font-family: Inter !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72 !important;
  text-align: center;
  color: #394052;
  align-self: center;
  max-width: 59%;
  margin-bottom: 1.875rem !important;
}

.popup {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 35%;
}

.popup img {
  width: 100%;
}

.container {
  position: relative;
}

.toprightClose {
  position: absolute;
  top: 0px;
  right: 3px;
  font-size: 18px;
  cursor: pointer;
  /* height: 30px; */
}

.bottomrightClose {
  position: absolute;
  bottom: 0px;
  right: 3px;
  font-size: 18px;
}

.allRectangles {
  display: flex;
  flex-direction: row;
}

.examRectangle {
  height: 40px;
  border-radius: 5px;
  background-color: #f7dbd4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 20px;
  padding: 0 15px;
}

.tutionRectangle {
  /* width: 83px; */
  height: 40px;
  border-radius: 5px;
  background-color: #dfddef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 18px;
  padding: 0 15px;
}

.testSeriesRectangle {
  /* width: 83px; */
  height: 40px;
  border-radius: 5px;
  background-color: #cfeffe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 20px;
  padding: 0 15px;
}

.blueSquare {
  width: 12px;
  height: 12px;
  margin: 0 7px 0 0;
  border-radius: 2px;
  background-color: #5580ea;
}

.lightBlueSquare {
  width: 12px;
  height: 12px;
  margin: 0 7px;
  border-radius: 2px;
  background-color: #dde7ff;
}

.startLearningWith {
  font-family: Inter !important;
  font-size: 2.375rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54 !important;
  /* letter-spacing: -1.44px !important; */
  text-align: center;
  color: #000;
  margin-top: 50px !important;
}

.getUnlimitedAccess {
  font-family: Inter !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55 !important;
  /* letter-spacing: 2.31px !important; */
  text-align: center;
  color: #6b6588;
  align-self: center;
  margin-bottom: 50px !important;
  margin-top: 20px !important;
}

.Mask {
  width: 90%;
  height: 8rem;
  margin: 0px 0px 30px 0px;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.kHeader {
  font-family: Inter !important;
  font-size: 1.875rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: normal !important; */
  text-align: center;
  color: #333;
}

.examTaken {
  font-family: Inter !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: normal !important; */
  text-align: center;
  color: #333;
}

.MaskBottom {
  width: 90%;
  margin: 20px;
  border-radius: 16px;
  box-shadow: 0 0 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #fff;
}

.animation_gif {
  width: 858px !important;
  height: 520px !important;
  -o-object-fit: cover;
     object-fit: cover;
  align-self: center;
  padding: 3% 0 5% 0;
}

.Learn-from-Expert {
  max-width: 464px;
  font-family: Inter !important;
  font-size: 2.5rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35 !important;
  /* letter-spacing: 4.01px !important; */
  color: #394052;
  margin: 1.875rem 0 !important;
}

.Learn-from-Expert .text-style-1 {
  text-decoration: underline;
  color: #5580ea;
}

.Discover-a-new-way {
  max-width: 422px;
  font-family: Inter !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94 !important;
  text-align: left;
  /* letter-spacing: 1.85px !important; */
  color: #394052;
}

.grid-padding {
  padding: 40px 100px;
}

.book-img {
  width: 7.188rem;
  height: 7.188rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.Competetive-Exam {
  font-family: Inter !important;
  font-size: 1.875rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: 2.23px !important; */
  color: #394052;
}

.grid-padding-2 {
  padding: 0.5rem;
  align-items: center;
}

.developer-activity-img {
  width: 80%;
  -o-object-fit: contain;
     object-fit: contain;
}

.justify_flex_end {
  display: flex;
  justify-content: flex-end;
}

.flex_column_justify_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  width: 70px;
  text-align: center;
}

.bgDiv {
  position: absolute;
  background-color: #f8f9ff;
  height: 75%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.subList {
  padding: 1113px 134px 1618px 133px;
  background-color: rgba(238, 240, 255, 0.4);
}

.ulList {
  list-style-type: none;
  margin: 14px 0 14px 0;
  font-family: Inter;
  font-size: 1.05vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: 2.08px; */
  color: #394052;
}

.view_box_content_text_li {
  list-style-type: square;

  font-family: Inter !important;
  font-size: 1.25vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67 !important;
  /* text-align: justify; */
  color: #394052;
}

.view_box_content_text_li:hover {
  color: #5580ea;
}

.book-trial-btn-small {
  /* margin: 1.875rem 0 !important; */

  /* background-color: #5580ea !important; */
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background-color: #5580ea; */
  align-self: center;
  /* // webkit: "skewX(-20deg)"; */
  transform: skewX(-6deg);
  padding: 0 2% !important;
  text-transform: capitalize !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.book-trial-btn-small:hover {
  box-shadow: 5px 10px 10px #afafaf;
}

.ok-btn-small {
  /* margin: 1.875rem 0 !important; */

  /* background-color: #5580ea !important; */
  background-image: linear-gradient(
    87deg,
    #5580ea -15%,
    #5580ea 118%
  ) !important;
  height: 40px;
  text-align: center;
  color: #fff !important;
  background-color: #5580ea;
  align-self: center;
  /* // webkit: "skewX(-20deg)"; */
  transform: skewX(-6deg);
  padding: 3% 2% !important;
  width: 23%;
  margin: 5% 0 !important;
  text-transform: capitalize !important;
}

.book-trial-text-small {
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: Inter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  transform: skewX(1deg);
}

.bgBottomDiv {
  position: absolute;
  background-color: #f8f9ff;
  height: 120vh;
  width: 100%;
  bottom: 0;
  z-index: -1;
}

.card {
  margin: 50px 0;
  /* padding-top: 40px; */
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
}

.Free-Trial-Btn {
  width: 150px;
  height: 45px;
  margin: 0 10px 0 0 !important;
  border: 0 !important;
  border-radius: 5px !important;
  transform: skewX(-6deg);
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.Free-Trial-Text {
  /* height: 20px; */
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-transform: capitalize;
  transform: skewX(1deg);
}

.Free-Trial-Btn:hover {
  box-shadow: 5px 10px 10px #afafaf;
}

.Login-Btn {
  width: 134px;
  height: 40px;
  margin: 0 0 0 10px !important;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  transform: skewX(-6deg);
}

.Login-Text {
  height: 20px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: normal; */
  color: #fff;
  text-transform: capitalize;
  transform: skewX(1deg);
}

.headerlogo {
  height: 50px !important;
  width: 122.8px !important;
  cursor: pointer;
  /* margin-right: 70px; */
}

.MuiTab-root {
  min-width: 100px !important;
}

.Mui-selected .MuiTab-wrapper {
  color: #5580ea !important;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.MuiTab-wrapper {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #394052;
  text-transform: capitalize;
}

.Start-Learning-with-footer {
  font-family: Inter !important;
  font-size: 40px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* letter-spacing: -0.5px !important; */
  color: #fff;
  margin-bottom: 13px !important;
}

.Discover-a-new-way-footer {
  max-width: 65%;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81 !important;
  /* letter-spacing: 0.15px !important; */
  color: #fff;
}

.BOOK-A-FREE-TRIAL-footer {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61 !important;
  letter-spacing: normal !important;
  color: #5580ea;
  transform: skewX(1deg);
}

.Learn-from-education-footer {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07 !important;
  /* letter-spacing: normal !important; */
  color: #000;
}

.Link-item-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  /* letter-spacing: normal; */
  color: #000 !important;
  margin-top: 25px !important;
}

.Links-header-text {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89 !important;
  /* letter-spacing: normal !important;  */
  color: #000;
}

.get-in-touch-item-text {
  font-family: Inter !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-decoration: none;
  /* letter-spacing: normal !important; */
  color: #000;
}

.All-rights-reserve-text {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1 !important;
  /* letter-spacing: normal !important; */
  text-align: center;
  color: #000;
  padding: 23px 0;
}

.call-email-img {
  width: 2rem;
  height: 2rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.social-media-img {
  width: 32px;
  height: 32px;
  margin-right: 3px;
  -o-object-fit: contain;
     object-fit: contain;
}

.MuiListItem-gutters {
  padding: 0 !important;
}

.kImage {
  width: 3rem !important;
  height: 3rem !important;
  -o-object-fit: contain;
     object-fit: contain;
  /* margin: 2rem; */
  margin-left: 20%;
}

.get-in-touch-padding {
  padding: 12px 12px 12px 0 !important;
}

.Committed-to-textlabel {
  font-family: Inter !important;
  font-size: 2.625rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  text-align: center;
  color: #394052;
  padding: 7% 0 2% 0;
}

.Become-a-part-textlabel {
  font-family: Inter !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72 !important;
  text-align: center;
  color: #394052;
}

.textlabel-slider-header {
  font-family: Inter !important;
  font-size: 2rem !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53 !important;
  color: #394052;
}

.textlabel-slider-text {
  font-family: Inter !important;
  font-size: 1.313rem !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65 !important;
  color: #394052;
  margin: 1.875rem 0 !important;
}

.slick-slider .slick-dots {
  list-style: none;
  padding-left: 0;
  display: flex !important;
  align-items: center;
}

.slick-slider .slick-dots li {
  position: relative;
  width: 12px;
  height: 12px;
  margin: 0 7px 0 0;
}

/* .slick-slider .slick-dots li::before {
    display:none;
  } */
.header-font {
  font-size: 17px;
}

.slick-slider .slick-dots li::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 12px;
  width: 12px;
  background-color: #dde7ff;
  border-radius: 2px;
  background-color: #dde7ff;
}

.slick-slider .slick-dots li.slick-active::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 12px;
  width: 12px;
  background-color: #5580ea;
  border-radius: 2px;
}

.slick-slider .slick-dots li button {
  /* display: none; */
  height: 12px;
  width: 12px;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.tiltImportant {
  padding: 50px 0 100px 0;
}

.slick-slider.tiltImportant .slick-dots {
  justify-content: flex-end;
  position: absolute !important;
  height: 20px;
  top: 0;
}

.slick-slider.tiltImportant .slick-dots li {
  width: 22.9px;
  height: 20px;
  margin: 0 14px;
}

.slick-slider.tiltImportant .slick-dots li button {
  /* display: none; */
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-slider.tiltImportant .slick-dots li::after {
  width: 22.9px;
  height: 20px;
  border-radius: 3px;
  transform: skewX(-6deg);
}

.slick-slider.tiltImportant .slick-dots li.slick-active::after {
  width: 22.9px;
  height: 20px;
  border-radius: 3px;
  transform: skewX(-6deg);
}

.landing-carousel {
  padding-top: 5% !important;
}

.slick-slider.landing-carousel .slick-next {
  top: -8px !important;
  height: 30px;
  right: 3% !important;
  width: 34px;
}

.slick-slider.landing-carousel .slick-prev {
  top: -8px !important;
  right: 8% !important;
  left: auto !important;
  height: 30px;
  width: 34px;
}

/* .more-item-list .MuiPopover-paper {
  padding: 1%;
} */
.more-item-list .MuiPaper-root {
  color: #394052;
  padding: 10%;
  margin-top: 10%;
  width: 100px;
  border-radius: 8px;
  display: inline-table;
}

.more-list-item {
  padding: 2% 0 !important;
}

.more_dropdown_arrow {
  height: 10px;
  width: 10px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-left: 10px;
}

.more-item-list .MuiMenuItem-root {
  font-family: Inter;
  padding: 10px 0 !important;
}

.more-item-list .MuiMenuItem-root:hover {
  border-bottom: 2px solid #5580ea !important;
  background-color: transparent !important;
}

.select-menu-item {
  background-color: transparent !important;
}

.select-menu-item:hover {
  border-bottom: 2px solid #5580ea !important;
  background-color: transparent !important;
}

.start_learning_now_btn {
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background: #5580ea !important; */
  transform: skewX(-6deg);
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  padding: 0 15px !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
}

.start_learning_now_btn_text {
  font-family: Inter !important;
  font-size: 20px !important;
  transform: skewX(1deg);
  text-transform: capitalize;
}

.js-scroll {
  /* opacity: 0; */
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.fade-in {
  -webkit-animation: fade-in 5s ease-in-out both;
          animation: fade-in 5s ease-in-out both;
}

.scrolled.fade-in-bottom {
  -webkit-animation: fade-in-bottom 5s ease forwards;
          animation: fade-in-bottom 5s ease forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-11 23:32:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes fade-in-bottom {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Remove inline style */

.landingPageHeaderBody {
  display: flex;
  position: relative;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.Talk {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.right-header {
  display: flex;
  align-items: center;
}
.get-in-touch-item-text p {
  margin: 0;
}

.relative {
  position: relative;
}
.pb-5 {
  padding-bottom: 5%;
}

.Unleash-outer {
  position: relative;
  background: #f8f9ff;
}
.Potential-content {
  display: flex;
  justify-content: center;
  padding-bottom: 4%;
}
.startLearningWith span {
  color: #9281e3;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* Responsive Media Query*/

@media (max-width: 1279px) {
  .Taking-Forward-The-N {
    font-size: 40px !important;
  }

  .textlabel_header-copy {
    max-width: 70%;
    font-size: 20px !important;
  }

  .book-img {
    width: 115px;
    height: 115px;
  }

  .bgDiv {
    height: 60%;
  }

  .headerlogo {
    height: 50px !important;
    width: 110px !important;
    margin-right: 20px;
  }

  .Free-Trial-Btn {
    width: 100px !important;
  }

  .Login-Btn {
    width: 98px !important;
  }

  .MuiTab-root {
    min-width: 80px !important;
  }

  .Free-Trial-Text {
    font-size: 12px;
  }

  .Login-Text {
    font-size: 11px;
  }

  .kImage {
    width: 50px !important;
    height: 50px !important;
  }

  .kHeader {
    font-size: 28px !important;
  }

  .examTaken {
    font-size: 14px !important;
  }

  .call-email-img {
    width: 35px;
    height: 35px;
  }

  .get-in-touch-item-text {
    font-size: 12px !important;
  }

  .social-media-img {
    width: 25px;
    height: 25px;
    margin-right: 0px;
  }

  .get-in-touch-padding {
    padding: 12px 5px 12px 0 !important;
  }

  .Competetive-Exam {
    font-size: 22px !important;
  }

  .Learn-from-Expert {
    font-size: 32px !important;
  }

  .startLearningWith {
    font-size: 42px !important;
  }

  .Discover-a-new-way {
    font-size: 12px !important;
  }

  .Committed-to-textlabel {
    font-size: 32px !important;
  }

  .textlabel-slider-header {
    font-size: 26px !important;
  }

  .textlabel-slider-text {
    font-size: 18px !important;
    margin: 20px 0 !important;
  }

  .startLearningWith {
    font-size: 32px !important;
    margin-top: 1.875rem !important;
  }

  .view_box_content_text_li {
    font-size: 18px !important;
  }

  .ulList {
    font-size: 14px !important;
  }

  .md-width-update {
    width: 85% !important;
  }

  .start_learning_now_btn {
    height: 55px;
  }
}

@media (max-width: 959px) {
  .Taking-Forward-The-N {
    font-size: 34px !important;
    max-width: 90%;
  }

  .popup {
    height: 53%;
    width: 50%;
  }

  .textlabel_header-copy {
    max-width: 75%;
    font-size: 16px !important;
  }

  .animation_gif {
    width: 570px !important;
    height: 370px !important;
  }

  .grid-padding {
    padding: 1.875rem 50px;
  }

  .Learn-from-Expert {
    font-size: 32px !important;
  }

  .startLearningWith {
    /* font-size: 34px !important; */
    margin-top: 1.875rem !important;
  }

  .kHeader {
    /* font-size: 36px !important; */
    margin-left: 8px !important;
  }

  .examTaken {
    /* font-size: 14px !important; */
    margin-left: 8px !important;
  }

  .center-text-small-screen {
    text-align: center;
    padding: 6% 10% 15% 6% !important;
  }

  .Learn-from-Expert {
    max-width: 100%;
  }

  .Discover-a-new-way {
    max-width: 100%;
    font-size: 10px;
    text-align: center;
  }

  .attend-now-btn-small {
    margin: 1.875rem 0 !important;
  }

  .justify-content-center-small-screen {
    justify-content: center !important;
  }

  .book-a-free-trial-small-btn {
    width: 150px !important;
  }

  .Start-Learning-with-footer {
    font-size: 32px !important;
  }

  .BOOK-A-FREE-TRIAL-footer {
    font-size: 12px !important;
  }

  .slick-slider .slick-dots {
    display: block !important;
  }

  .slick-slider.tiltImportant .slick-dots {
    display: flex !important;
  }

  .start_learning_now_btn {
    height: 50px;
    padding: 0 10px !important;
    align-items: center;
  }

  .start_learning_now_btn_text {
    font-size: 20px !important;
  }

  .top-spacing {
    margin-left: -14% !important;
  }

  .md-width-update {
    width: 70% !important;
  }

  .developer-activity-img {
    width: 100%;
  }

  .textlabel-slider-header {
    font-size: 22px !important;
  }

  .textlabel-slider-text {
    font-size: 14px !important;
    margin: 10px 0 !important;
  }
}

@media (max-width: 969px) {
  .landing-carousel {
    padding-top: 18% !important;
  }

  .slick-slider.landing-carousel .slick-next {
    top: 37px !important;
    right: 7% !important;
  }

  .slick-slider.landing-carousel .slick-prev {
    top: 37px !important;
    right: 18% !important;
  }
}

@media (max-width: 599px) {
  .Taking-Forward-The-N {
    font-size: 28px !important;
  }

  .popup {
    height: 41%;
    width: 50%;
  }

  .start_learning_now_btn {
    width: 190px;
  }

  .start_learning_now_btn_text {
    font-size: 17px !important;
  }

  .animation_gif {
    width: 290px !important;
    height: 270px !important;
    padding: 0;
  }

  .grid-padding {
    padding: 16px;
  }

  .Learn-from-Expert {
    font-size: 24px !important;
    margin: 0 0 20px 0 !important;
  }

  .book-img {
    width: 75px;
    height: 75px;
  }

  .Competetive-Exam {
    font-size: 18px !important;
    display: flex;
    justify-content: center;
  }

  .grid-padding-2 {
    align-items: center;
    padding: 14px;
    flex-direction: column;
  }

  .examRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .allRectangles {
    justify-content: space-between;
  }

  /* .allRectangles {
    display: flex;
    flex-direction: column;
    width: 170px;
  } */

  .tutionRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .testSeriesRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bgDiv {
    height: 55%;
  }

  .startLearningWith {
    font-size: 26px !important;
  }

  .kHeader {
    font-size: 28px !important;
  }

  .examTaken {
    font-size: 18px !important;
  }

  .bgBottomDiv {
    height: 170vh;
  }

  .headerlogo {
    padding-left: 25px;
  }

  .Start-Learning-with-footer {
    font-size: 32px !important;
  }

  .Discover-a-new-way-footer {
    max-width: 85%;
    font-size: 14px !important;
    padding-bottom: 20px;
  }

  .textlabel_header-copy {
    max-width: 75%;
    font-size: 12px !important;
  }

  .Committed-to-textlabel {
    font-size: 24px !important;
  }

  .Become-a-part-textlabel {
    font-size: 16px !important;
  }

  .textlabel-slider-header {
    font-size: 24px !important;
  }

  .textlabel-slider-text {
    font-size: 14px !important;
    margin: 10px 0 !important;
  }

  .top-spacing {
    margin-left: -25px !important;
  }

  .md-width-update {
    width: 90% !important;
  }
}

@media (max-width: 469px) {
  .examRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup {
    height: 45%;
    width: 70%;
  }

  .start_learning_now_btn {
    width: 190px;
  }

  .start_learning_now_btn_text {
    font-size: 17px !important;
  }

  .tutionRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .testSeriesRectangle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .textlabel_header-copy {
    max-width: 75%;
    font-size: 12px !important;
  }

  .headerlogo {
    height: 1.875rem !important;
    width: 60px !important;
    padding-left: 20px;
  }

  .Free-Trial-Btn {
    width: 80px !important;
    height: 35px !important;
    margin: 0 5px 0 0 !important;
  }

  .Login-Btn {
    width: 80px !important;
    height: 35px !important;
    margin: 0 !important;
  }

  .Free-Trial-Text {
    font-size: 12px;
  }

  .Login-Text {
    font-size: 9px;
  }

  .top-spacing {
    top: 15px !important;
  }

  .MuiButton-outlined {
    padding: 5px !important;
  }

  .book-trial-text-small {
    font-size: 17px !important;
  }

  .examTaken {
    font-size: 16px !important;
  }

  .get-in-touch-padding {
    padding: 12px 1px 12px 0 !important;
  }
}

.left-section-panel {
  /* height: inherit; */
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* border-radius: 8px; */
}

.left-section-grid {
  width: 100%;
  /* height: inherit; */
  background-color: #5580ea;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15%;
  text-align: center;
}

.zoho-left-panel-img {
  width: 70%;
  height: 220px;
  -o-object-fit: contain;
     object-fit: contain;
}

.form-modal {
  justify-content: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  border-radius: 20px;
  max-height: 100%;
  overflow: scroll;
  width: 60%;
  /* padding: 3%; */
  overflow: auto;
}

.close-icon-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.Registration {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #394052;
}
.Enroll {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 800 !important;
  margin: 5% !important;
  color: #e6e9f3;
}
.textfield {
  width: 100% !important;
  border-radius: 6px;

  font-size: 15;
  /* opacity: 0.6; */
  border-radius: 3.9px;
  /* border: solid 0.8px #5580ea; */
  background-color: #f9faff;
}

.textfield .MuiOutlinedInput-input {
  /* color: #000000 !important; */
}

.select {
  width: 100% !important;
  border-radius: 6px;
  color: #3f5267;
  font-size: 15;
  opacity: 1;
  border-radius: 3.9px;
  border: solid 0.8px #5580ea;
  background-color: #f9faff;
  padding: 16px 14px;
}

.select-course {
  width: 100% !important;
  border-radius: 6px;
  color: #3f5267;
  font-size: 15;
  opacity: 1;
  border-radius: 3.9px;
  border: solid 0.8px #5580ea;
  background-color: #f9faff;
  padding: 16px 14px;
}

.marginBottom {
  margin-bottom: 2% !important;
}
.marginTop {
  margin-top: 2% !important;
}

.form-label {
  opacity: 1 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  margin-bottom: 2% !important;
  white-space: nowrap !important;
}

.checkbox.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3a60d7;
}

.policy {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.privacy-terms {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  color: #394052;
  margin-left: 2%;
}

.submit-btn {
  width: 130px;
  padding: 3%;
  margin: 0 10px 0 0 !important;
  border-radius: 5px !important;
  /* border: solid 1px #5580ea !important; */
  transform: skewX(-6deg);
  background-color: #5580ea !important;
  margin-left: 5% !important;
}

.cancel-btn {
  width: 130px;
  padding: 3%;
  margin: 0 10px 0 0 !important;
  border-radius: 5px !important;
  /* border: solid 1px #5580ea !important; */
  transform: skewX(-6deg);
  background-color: #e3ebff !important;
}

.submit-zoho-form-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.cancel-zoho-form-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #394052;
}
.batchInputContainer {
  flex-wrap: nowrap !important;
  grid-gap: 10px;
  gap: 10px;
}

.zoho-course-item > .MuiTypography-body1 {
  line-height: unset !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  font-size: 14px !important;
  color: #394052;
}

.zoho-course-item.MuiListItemText-root {
  margin: 0 !important;
  font-size: 14px !important;
  color: #394052;
}

@media only screen and (max-width: 1300px) {
  .form-label {
    font-size: 13px !important;
  }
  .submit-zoho-form-text {
    font-size: 12px;
  }
  .cancel-zoho-form-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1050px) {
  .form-label {
    font-size: 12px !important;
  }
  .submit-zoho-form-text {
    font-size: 12px;
  }
  .cancel-zoho-form-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .batchInputContainer {
    flex-wrap: wrap !important;
    grid-gap: 0px;
    gap: 0px;
  }
  .batchInputContainer:nth-child(2) {
    margin-top: 100px;
  }
  .form-modal {
    width: 90%;
  }
  .marginBottomSmall {
    margin-bottom: 2% !important;
  }
}

.img-customer-service {
    width: 314px;
    height: 314px;
    -o-object-fit: contain;
       object-fit: contain;
  }

.Thankyou-for-sharing {
    font-family: Inter !important;
    font-size: 24px !important;
    font-weight: bold !important;
    text-align: center;
    color: #3f526d;
    margin: 1% 0 !important
  }
  .thankyou-modal {
    justify-content: center !important;
    position: absolute !important;
    border-radius: 20px;
    height: 80%;
    width: 80%;
    /* padding: 3%; */
    overflow: auto;
}
.Our-team-would-get-text {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-align: center;
    color: #3f526d;
  }

  @media (max-width: 599px) { 
    .img-customer-service {
      width: 90%;
      height: auto;
    }
    .Thankyou-for-sharing {
      font-size: 20px !important;
      width: 95%;
    }
    .Our-team-would-get-text {
      font-size: 12px !important;
      width: 95%;
    }

  }


    .trialButton  {
        padding: 0.8rem; 
        font-weight: "bold";
        transform: skewX(-6deg);
        border-radius: 10px;
        width: 217px;
        height: 61px;
        background-color: white;
    }
    
    .footerWrapper {
        justify-content: space-evenly;
        /* width: "90%"; */
        background-color: rgb(238,242,254);
    }
    .innerFooterLeft {        
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    }
    .innerFooterRight {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footerLogo {
        margin-bottom: 25px;
        /* width: 80%; */
        width: 154.7px;
        height: 63px;
    }
    .getInTouchWrapper {
        display: flex;
        align-items: center;
        /* padding-top: 13px; */
    }  
    /* .getInTouchWrapper :first-of-type {
            margin-top: 130px;
        } */
    
    .divider  {
        height: 2;
        color: #969696;
    }
    .reservedRights  {
        background-Color: rgb(238,242,258);
    }




.landingPageContainer {
  background: "linear-gradient(180deg, #f8f9ff 70%, #fff 30%)";
}

.landingPage-attend-block {
  display: flex;
  justify-content: center;
}

.classroomWrapper {
  display: flex;
  justify-content: center;
  background-color: white;
}

.liveClassContainer {
  background-color: #f8f9ff;
}

.liveClassHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.carouselContainer {
  padding: 6% 0% 6% 6% !important;
  cursor: pointer;
}

.classroomInformationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-left: 15%;
}

.classroomInformation-button {
  max-width: 190px;
  height: 60px;
  text-align: center;
  color: #fff !important;
  /* background-color: #5580ea !important; */
  transform: skewX(-6deg);
  margin-top: 23px !important;
  background-image: linear-gradient(87deg, #f7ba51 -15%, #f79f84 118%) !important;
  box-shadow: 1px 10px 10px #f79f84 !important;
  cursor: pointer;
}

.classroom-button-label {
  font-family: Inter;
  font-size: 20px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  transform: skewX(1deg);
  text-transform: capitalize;
}

.footer-success {
  display: flex;
  justify-content: space-between;
  background-size: contain;
  width: 100%;
}

.flex-item-center {
  display: flex;
  align-items: center;
}
.miniCard {
  background-color: #fff;
  border-radius: 10px !important;
  cursor: "pointer";
}

.competitiveExam {
  border: solid 2px #f7dbd4;
}

.competitiveExam:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
}

.schoolTution {
  border: solid 2px #dfddef;
  margin-top: 52px;
}

.schoolTution:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
}

.examTestSeries {
  border: solid 2px #cfeffe;
  margin-top: 52px;
}


.examTestSeries:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1) !important;
  }

  .Potential-content .justify-content-center-small-screen{
    display: flex;
    justify-content: center;
  }
  .icon-center {
    align-self: center;
  }

  .align-last {
    align-self: center;
    -moz-text-align-last: left;
         text-align-last: left;
    padding-left: 5px;
  }
.subjectTags{
    font-family:  Inter;
    font-size:  1.05em;
    font-weight: 600 !important;
    font-stretch:  normal;
    font-style:  normal;
    
 
}

.competitiveTags {
    color:  #e06e53;
}

.tutionTags {
    color: #8179c0 ;
}

.examSeriesTags {
    color: #40c1fa;
}


.Lets-Competetive-Ex {
    font-family: Inter !important;
    font-size: 3.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69 !important;
    color: #394052;
    letter-spacing: 0.00938em !important;
  }
 .selectclass{
    margin: 10px 10px 10px 10px !important;
    font-size: large !important;
    
    color: #5e636d !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;


  }
  .selectclass:hover{
    color: #5580ea !important;

  }
  .class6{
    transform: skewX(6deg) !important;

  }
  .selectclassmainbox{
    background-color: #ecefff;
     margin-left:1px!important ;
     border-radius: 5px;
    cursor: pointer;
    text-transform: capitalize;
    height: 50;
    font-weight: 600!important;
    font-size: 20px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

  }
  .main-box-for-class-and-subject{
    display: flex;
    flex-direction: row!important;
    padding: 20px 0px 5px ;
    
    align-items: center;
    justify-content: left!important;

  }
  

  .Lets-Competetive-Ex-student-centric{
    font-family: Inter !important;
    font-size: 3.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.30 !important;
    letter-spacing: 0 !important;
    color: #394052;
  }
  .Lets-Competetive-Ex .text-style-1 {
    color: #5580ea;
    text-decoration: underline;
  }
  .textlabel_description_1 {
    color: #5a606e;
    font-family: Inter !important;
    font-size: 1.53vw !important;
    font-weight: 600 !important;
    letter-spacing: 0 !important;
    line-height: 1.73 !important;
    font-style: normal;
    font-stretch: normal;
    margin: 2% 0px 5% 0px !important;    
  }
  .ulList_2 {
    list-style-type: none;
  margin: 14px 0 14px 0;
  font-family: Inter;
  font-size: 1.05vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.view_box_content_text_li_2 {
  list-style-type: square;
  font-family: Inter !important;
  font-size: 1.25vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67 !important;
  color: #394052;
}
.view_box_content_text_li_2:hover {
  color: #5580ea;
}


  .online-learning-img{
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .Search-Bg-Rectangle {
    width: 400px;
    height: 50px;
    padding: 12px;
    box-shadow: 0 2px 4px 0 #d0d6f9;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }

  .Search-Rectangle {
    width: 20px;
    height: 20px;
    padding: 14px;
    border-radius: 5px;
    background-color: #f1f6ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Search-Img {
    width: 22px;
    height: 22px;
    -o-object-fit: contain;
       object-fit: contain;
  }

.Search-Btn {
  width: 80px;
  height: 45px;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  align-self: center;
  transform: skewX(-6deg);
}

.Search-Text {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #fff;
    text-transform: capitalize;
    transform: skewX(6deg);
  }

  .Search-for-course {
    border: none !important;
    border-bottom: solid 1px #bbc4f9 !important;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    color: #394052;
    width: 240px;
  }

  .Courses {
    font-family: Inter !important;
    font-size: 2.64vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92 !important;
    color: #394052;
    margin: 15% 0 !important;
  }

  .Courses-Rectangle {
    padding: 5% 8%;
    border-radius: 5px;
    box-shadow: 0 2px 11px 5px rgba(225, 229, 255, 0.3);
    background-color: #fff;
    margin-right: 12%;
    
  }

  .Courses-Text {
    font-family: Inter !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
    padding: 28px 0;
    cursor: pointer;
  }

.Courses-img {
  width: 30px !important;
  height: 30px !important;
  margin: 0 23px 0 0;
  -o-object-fit: contain;
     object-fit: contain;
}

.Selected-Rectangle {
  height: 50px; 
  border-radius: 5px;
  background-color: #5580ea;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 38px;
}

.Selected-Text {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #fff;
}

.Unselected-Rectangle {
  height: 50px; 
  border-radius: 5px;
  background-color: #ecefff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  padding: 0 38px;
}

.Unselected-Text  {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #5580ea;
}

.Courses-Header {
  margin: 4% 0 1% 0 !important;
  font-family: Inter !important;
  font-size: 1.94vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.07 !important;
  color: #394052;
  cursor: pointer;
}

.Courses-Details {
  border-radius: 20px;
  border: solid 3px #f1f6ff;
  background-color: #fff;
  cursor: pointer;
}

.Course-Name {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67 !important;
  color: #394052;
  text-align: left;
  margin-top: 21px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  height: 80px;
}

.Know-More-Text{
  font-family: Inter !important;
  margin-top:8px !important;
  font-size: 16px !important; 
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
  text-decoration: underline;
  display: flex;
  text-align: center;
}

.Course-Highlights-card{
  margin: 0 0 50px 0;
  padding: 4%;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
}

.Course-Highlights {
  font-family: Inter !important;
  font-size: 2.64vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
}

.Get-assistance-text {
  font-family: Inter !important;
  font-size: 1.53vw !important;
  font-weight: 550 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  color: #394052be;

}

.About-NEET-Text {
  font-family: Inter !important;
  font-size: 2.5vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
}

.National-Eligibility-Text {
  font-family: Inter !important; 
  font-size: 18px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83 !important;
  color: #495166;
  margin: 15px 0 !important;
} 
.text-style-10 {
  font-family: Inter !important; 
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83 !important;
  margin: 15px 0 !important;
  font-weight: bold;
  color: #5580ea;
  cursor: pointer;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  
}

.Teachze-is-a-pioneer-text {
  font-family: Inter !important;
  font-size: 32px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47 !important;
  text-align: center;
  color: #394052;
  margin: 100px 100px 60px 100px !important;
}

.spacing-100{
  padding: 4% 0
}


.This-platform-will-text {
  font-family: Inter !important; 
  font-size: 1.53vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  color: #394052;
  margin: 29px 0 !important;
}

.quote-img{
    width: 81.6px;
    height: 60px;
    -o-object-fit: contain;
       object-fit: contain;
    position: absolute;
    z-index: -1;
}

.blueTiltSquare{
  width: 22.9px;
  height: 20px;
  margin: 0 14px 0 0;
  border-radius: 3px;
  background-color: #5580ea;
  transform: skewX(-6deg);
}

.lightBlueTiltSquare{
    width: 22.9px;
    height: 20px;
    margin: 0 14px;
    border-radius: 3px;
    background-color: #dde7ff;
    transform: skewX(-6deg);
  }

  .view_box {
    border-radius: 16px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    padding: 50px 25px 50px 25px;
    margin: 10px !important;
    cursor: pointer;
  }

  .view_img{
    width: 85px;
    height: 85px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .view_line {
    margin: 27px 0;
    border: solid 1px #efefef;
  }

  .view_text {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59 !important;
    color: #000;
    height:40
  }

  .view_name {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #000;
  }

  .view_parent {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #677788;
    margin-top: 8px !important;
  }
  
  .bitmap-img{
    width: 100%;
  }

  .Hear-from-text {
    font-family: Inter !important;
    font-size: 2.64vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92 !important;
    color: #394052;
    padding-left: 45px;
  }
  .paddingRight20{
    padding-right: 20px;
  }

  .relative {
    position: relative;
  }
.root-outer {
  flex-grow: 1;
  overflow-x: hidden;
  position: relative;
  
}
.Competitive-container{
  display: flex;
  justify-content: center;
  background: #f8f9ff;
  padding-bottom: 2%;
}
.Competetive-info-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  
}

.justifyContent-center {
  display: flex;
  justify-content: center;
}

.react-reveal .xs-4{
  flex-basis: 0;
  align-self: center;
}

.xs-4{
  flex-basis: 0 !important;
  align-self: center !important;
}

.tabslist {
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 25px;
  padding: 0 38px;
  margin: 3px;
  text-transform: capitalize;
  cursor: pointer;
  transform: skewX(-6deg);

}
.tabslist .innertabs {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  transform:skewX(6deg);
}

.Course-Highlights-card .faculty-info{

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: #464c5d;
  margin-top: 7%;
}
.image-course {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.less-margin-small-screen{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 50px 25px 10px;

}
.Arrows{
  width: 34px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.col50{
 display: flex;
 justify-content: center;
 flex-direction: column;
 padding-left: 10px;
 height: 50px;
}

.img-source{
  width: 35px;
  height: 35px;
  -o-object-fit: contain;
     object-fit: contain;
}

.direction-row {
  display: flex;
  flex-direction: row;
}

.direction-column {
  display: flex;
  flex-direction: column;
}

.footer-success {
  display: flex;
  justify-content: space-between;
  background-size: contain;
  width: 100%;
}
.course-highlight{
  display: flex;
    justify-content: center;
    background: linear-gradient(rgb(255, 255, 255) 55%, rgb(248, 249, 255) 45%);
    padding-bottom: 0px;

}

.image-faculty {
  height: 52px;
  width: 52px;
  -o-object-fit: contain;
     object-fit: contain;
}

.Competitive-container-info{
  display: flex;
  background: #f8f9ff;
  justify-content: center;
}
.start-learning {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  text-align: center;
  text-transform: capitalize;
  transform: skewX(1deg);
}



.small-title {
  font-family: Inter;
  font-size: 1.53vw;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
 width: 90%;
 color: #394052;
}
.small-title1{
 font-size: 16px;
 line-height: 1.49;
 color: #6b6f79;
 margin-top: 20px;
}
.pl{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15%;
}
.dis-jcenter{
  display: flex;
  justify-content: center;
}
.ptop3{
  padding-top: 3%;;
}
.item50 {
  width: 52px;
  height: 52px;
  -o-object-fit: contain;
     object-fit: contain;
}
.text-left {
  text-align: left !important;
}

.cascade-Name {
  font-family: Inter;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  color: #394052;
  margin-bottom: 22px !important;

}

.secondary-tabs{
  background: #5580ea;
  color: #fff;
  width: 30px;
  height: 30px;
  transform: skewX(-6deg);
  border-radius: 5px;
  margin: 0 5px;
  align-self: center;
  opacity: 1;
}

.filteredTabsList {
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 38px;
  margin: 3px;
  cursor: pointer;
  text-transform: capitalize;
  transform: skewX(-6deg);

}

.filteredTabsList .innertabs {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  transform:skewX(6deg);
}


  @media (max-width: 1279px){
    .Lets-Competetive-Ex{
      font-size: 40px !important;
    }
    .Lets-Competetive-Ex-student-centric {
      font-size: 40px !important;
    }
    .view_img{
      width: 71px;
    }
    .view_name{
      font-size: 20px !important;
      padding-left: 10px;
    }
    .view_parent{
      font-size: 20px !important;
      padding-left: 10px;
    }
    .less-margin-small-screen{
      margin: 20px 30px 25px 10px !important;
      padding-top: 10px;
    }
    .About-NEET-Text {
      font-size: 30px !important;
    }
    .This-platform-will-text {
      font-size: 20px !important;
    }
    .view_box_content_text_li_2 {
      font-size: 18px !important;
    }
    .ulList_2 {
      font-size: 14px !important;
    }
    .Courses-img {
      margin: 0 8px 0 0;
    }
    .Hear-from-text {
      font-size: 30px !important;
    }
    .textlabel_description_1{
      font-size: 20px;
    }
    .Courses{
      font-size: 32px !important;
    }
    .Course-Highlights {
      font-size: 32px !important;
    }
    .view_text {
      font-size: 12px !important;
    }
    .Get-assistance-text {
      font-size: 20px !important;
    }
    .md-font-20 {
      font-size: 20px !important;
    }
    .Course-Name {
      font-size: 16px !important;
      margin-top: 12px !important;
    }
    .Know-More-Text {
      font-size: 14px !important;
      margin-top: 0 !important;
      align-items: center;
    }
    .md-font-18 {
      font-size: 18px !important;
    }
    .Courses-Header {
      font-size: 24px !important;
    }
    .National-Eligibility-Text {
      font-size: 16px !important;
    }
    .text-style-10 {
      font-size: 16px !important;
    }
  }

  @media (max-width: 959px){
    .Lets-Competetive-Ex{
      font-size: 34px !important;
    }
    .Lets-Competetive-Ex-student-centric {
      font-size: 32px !important;
    }
    .textlabel_description_1 {
      font-size: 18px !important;
    }
    .bitmap-img{
      padding-top: 20px;
      -o-object-fit: contain;
         object-fit: contain;
      width: 70%;
    }
    .padding-right {
      padding-right: 30px;
    }
    .Teachze-is-a-pioneer-text{
      margin: 60px !important;
    }
    .Hear-from-text{
      font-size: 28px !important;
    }
    .quote-img{
      width: 62.6px;
      height: 44px;
    }
    .Courses-Rectangle{
      margin-bottom: 36px;
      margin-right: 10px;
    }
    .Get-assistance-text{
      width: 95%;
    }
    .less-margin-small-screen{
      margin: 20px 30px 25px 10px !important;
      padding-top: 0px;  
    }
    .About-NEET-Text {
      font-size: 28px !important;
    }
    .This-platform-will-text {
      font-size: 16px !important;
    }
    .Courses{
      font-size: 28px !important;
    }
    .Course-Highlights {
      font-size: 28px !important;
    }
    .bitmap-img-align {
      display: flex;
      justify-content: center;
    }
    .sm-font-18 {
      font-size: 18px !important;
    }
   
  }

@media (max-width: 599px){
  .padding-right {
    padding-right: 20px;
  }
  .Search-Bg-Rectangle{
    width: 320px;
  }
  .Search-for-course{
    width: 200px;
  }
  .Search-Text{
    font-size: 14px !important;
  }
  .Lets-Competetive-Ex{
    font-size: 30px !important;
  }
  .Lets-Competetive-Ex-student-centric {
    font-size: 30px !important;
  }
  .Teachze-is-a-pioneer-text{
    margin: 30px !important;
    font-size: 24px !important;
  }
  .About-NEET-Text {
    font-size: 24px !important;
  }
  .Courses{
    font-size: 30px !important;
    margin: 20px !important;
  }
  .Course-Highlights {
    font-size: 26px !important;
  }
  .Course-Highlights-card{
    margin: 0;
    padding: 20px;
  }
  .Hear-from-text{
    font-size: 26px !important;
  }
  .quote-img{
    width: 70px;
    height: 36px;
  }

  .view_box {
    padding: 20px;
  }
  .paddingRight20{
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .xs-font-16 {
    font-size: 16px !important;
  }
  .xs-font-14 {
    font-size: 14px !important;
  }
  .Get-assistance-text {
    font-size: 18px !important;
  }
  .Courses-Text {
    padding: 23px 0;
  }
}

@media (max-width: 399px){
  .Search-Bg-Rectangle{
    width: 260px;
  }
  .Search-for-course{
    width: 128px;
    font-size: 14px;
  }
    
  .Search-Btn{
    height: 50px;
    margin-left: 10px !important
  }
    
  .Search-Rectangle {
    width: 22px;
    height: 22px;
    padding: 10px;
  }
  .less-margin-small-screen{
    margin: 20px 50px 25px 10px !important;
    padding-top: 0px;  
  }
}
.width-70per {
  width: 80%;
}

.Course-Details-Title {
  font-family: Inter !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #5580ea;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}

.Change-Btn {
  width: 80px;
  height: 30px;
  border-radius: 5px !important;
  margin-left: 20px !important;
  transform: skewX(-6deg);
  background-color: #ffc101 !important;
  border: none !important;
}

.Change-Text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-transform: capitalize;
  transform: skewX(1deg);
}

.Free-Trial-Course-Btn {
  width: 130px;
  height: 45px;
  margin: 0 10px 0 0 !important;
  border-radius: 5px !important;
  /* border: solid 1px #5580ea !important; */
  background-image:  linear-gradient(87deg, #f7ba51 -15%, #f79f84 118%) !important;
  transform: skewX(-6deg);
  /* background-color: #5580ea !important; */
  box-shadow: 3px 6px 10px  #f79f84 !important
  
}

.Free-Trial-Course-Text {
  /* height: 20px; */
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-transform: capitalize;
  transform: skewX(1deg);
}

.Login-Course-Btn {
  width: 130px;
  height: 45px;
  margin: 0 0 0 10px !important;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  transform: skewX(-6deg);
}

.Login-Course-Text {
  height: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #fff;
  text-transform: capitalize;
  transform: skewX(1deg);
}

.textlabel_heading {
  font-family: Inter !important;
  font-size: 3.3vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  color: #394052;
}

.textlabel_heading_course {
  font-family: Inter !important;
  font-size: 2.8vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  color: #394052;
}

.our-repeater-course-text {
  font-family: Inter !important;
  font-size: 1.2vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* text-align: justify; */
  color: #394052;
  margin-top: 20px !important;
}

a:-webkit-any-link {
  color: #5580ea;
}

.learn-more-text {
  font-family: Inter !important;
  font-size: 1.2vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* text-align: justify; */
  color: #5580ea;
  margin-bottom: 20px !important;
}

.feature_box {
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  cursor: pointer;
  max-width: 400px !important;
  text-align: center;
  padding: 10%;
  height:330px
  /* min-height: 450px; */
}

.header-img {
  width: 100%;
  /* height: 602px  */
}

.feature_img {
  height: 72px;
  /* width: 100px; */
  padding: 10% 0;
}

.feature_title_course {
  font-family: Inter !important;
  font-size: 1.25vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  padding-bottom: 18px;
  height: 50px;
}
.feature_title_box {
  height: 100px;
}

.feature_desc {
  font-size: 1vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  height: 100px;
}
.feature_desc_box {
  height: 100px;
  font-family: Inter !important;
}

.textlabel_knowmore {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #5580ea;
  margin-top: -20;
}

.course-highlight-view-box {
  height: 136px;
  border-radius: 16px;
  box-shadow: 0 -8px 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #f8f9ff;
  padding: 9%;
}

.course-highlight-image {
  width: 60px !important;
  height: 60px !important;
  -o-object-fit: contain;
     object-fit: contain;
}

.course-highlight-header {
  font-family: Inter !important;
  font-size: 1vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: left;
  color: #464c5d;
}
.faq-below-text {
  /* width: 800px;
    height: 66px; */
  margin: 7px 0 0 0;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  /* letter-spacing: 2px; */
  color: #4b4e53;
}
.get_assi_text {
  font-family: Inter !important;
  font-size: 1.6vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  color: #4b4e53;
  width: 90%;
}
/* .sugg-below-text{
    width: 700px;
    height: 66px;
    margin: 7px 0 0 0;
    font-family: Inter;
    font-size: 22px;
    font-weight: 510;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: 2px;
    color: #5b5d63;
  
  } */

.course-highlight-text {
  font-family: Inter !important;
  font-size: 1.26vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
}

.batch-view-box {
  border-radius: 5px;
  box-shadow: 0 0 13px 3px rgba(97, 116, 169, 0.05);
  background-color: #fff;
  padding: 23px 17px;
}

.textlabel_batches {
  font-family: Inter !important;
  font-size: 2.7vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: hsl(223, 9%, 38%);
}

.textlabel_batchname {
  font-family: Inter !important;
  font-size: 1.95vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32 !important;
  color: #394052;
  margin: 20px 0 !important;
}

.textlabel_viewsschedule {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #233c79;
  text-decoration: underline;
  cursor: pointer;
}

.textlabel_startdate {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19 !important;
  color: #394052;
  margin: 9px 0 !important;
}

.subject_view_box {
  width: 64px;
  height: 25px;
  border-radius: 4px;
  background-color: #e1e9fb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textlabel_language_hindi {
  font-family: Inter !important;
  font-size: 1vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #233c79;
}

.spacing-60 {
  padding: 60px 0;
}

.view_viewallbox_course {
  width: 14vw;
  height: 4.2vw;
  border-radius: 5px !important;
  background-color: #5580ea !important;
  transform: skewX(-6deg);
  margin-left: 1px;
}

.textlabel_viewall_course {
  font-family: Inter !important;
  font-size: 1.4vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #fff;
  transform: skewX(1deg);
  text-transform: capitalize;
}

.view_box_subject {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
  padding: 10% 16%;
  width: 110px;
}

.textlabel_subjectname {
  /* width: 105px; */
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  padding: 5% 0;
}

.textlabel_viewsyllabus {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  padding-bottom: 12%;
  text-decoration: underline;
}

.course_sub_view_box {
  border-radius: 16px;
  box-shadow: 0 -8px 16px 0 rgba(246, 246, 246, 0.57);
  background-color: #fff;
  padding: 3.5%;
}

.sub_item_view_box {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 5% 5%;
  width: 75%;
  height: 60%;
}

.textlabel_personal {
  font-family: Inter !important;
  font-size: 1.68vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
}

.textlabel_details {
  font-family: Inter !important;
  font-size: 1.26vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
  margin-top: 12px !important;
}

.features_included_view_box_border {
  border-radius: 9px;
  border: solid 0.5px #557fe9;
}

.textlabel_features {
  font-family: Inter !important;
  font-size: 1.95vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18 !important;
  color: #394052;
}

.view_subscription {
  width: 100%;
  padding: 2%;
  border-radius: 5px;
  background-color: #f1f5fe;
  transform: skewX(-6deg);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.view_selected {
  border-radius: 5px !important;
  background-color: #5580ea !important;
  padding: 5% 0 !important;
}

.textlabel_selected {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06 !important;
  color: #fff;
  transform: skewX(1deg);
  text-transform: capitalize;
}

.textlabel_notselected {
  font-family: Inter !important;
  font-size: 1.12vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06 !important;
  color: #394052;
  transform: skewX(1deg);
  text-transform: capitalize;
}

.textlabel_live {
  font-family: Inter !important;
  font-size: 1.55vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
  padding: 3% 0;
}

.btn_buyfullcourse {
  border-radius: 9px !important;
  background-color: #5580ea !important;
  padding: 2% 6% !important;
  transform: skewX(-6deg);
}

.textlabel_buyfullcourse {
  font-family: Inter !important;
  font-size: 1.32vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
  transform: skewX(1deg);
}

.textlabel_discount {
  font-family: Inter !important;
  font-size: 1.55vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
}

.textlabel_price {
  font-family: Inter !important;
  font-size: 2.2vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #5580ea;
}

.margin-top-minus {
  margin-top: -115px !important;
}

.quote-image {
  width: 81.6px;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;

  /* position: absolute;*/
  /* z-index: -1;  */
}

.Hear-from-our-text {
  font-family: Inter !important;
  font-size: 2.6vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
  /* padding-left: 45px; */
  margin-left: -25px !important;
}

.view_name_text {
  font-family: Inter !important;
  font-size: 1.85vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #000;
}

.view_parent_text {
  font-family: Inter !important;
  font-size: 1.75vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #677788;
  margin-top: 8px !important;
}

.headerImageLogo {
  height: 50px !important;
  width: 122.8px !important;
}

.overlap_faq_box {
  /* width: 1170px;
    height: 164px;
    margin: 100px 0.4px 854px 2px;
    padding: 49px 61px 48px 55px; */
  padding: 2%;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  margin-top: -5% !important;
}

@media (max-width: 1279px) {
  .course-highlight-image {
    width: 45px !important;
    height: 45px !important;
  }

  .course-highlight-header {
    font-size: 20px !important;
  }

  .course-highlight-text {
    font-size: 16px !important;
  }

  .headerlogo {
    height: 50px !important;
    width: 110px !important;
  }
  .view_box {
    padding: 30px 12px;
  }
  .course-highlight-view-box {
    padding: 18px;
  }
  .Change-Btn {
    margin: 0 10px !important;
  }
  .view_box_subject {
    padding: 5%;
  }
  .quote-image {
    width: 70px;
    height: 50px;
  }
  .feature_title_course {
    font-size: 18px !important;
  }
  .feature_desc {
    font-size: 12px !important;
  }
  .textlabel_knowmore {
    font-size: 14px !important;
  }
  .textlabel_heading {
    font-size: 34px !important;
  }
  .textlabel_heading_course {
    font-size: 34px !important;
  }
  .our-repeater-course-text {
    font-size: 18px !important;
  }
  .enroll-now-small-text {
    font-size: 16px !important;
  }
  .faq-below-text {
    font-size: 18px !important;
  }
  .small-chapter-name {
    font-size: 18px !important;
  }
  .textlabel_startdate {
    font-size: 14px !important;
  }
  .textlabel_language_hindi {
    font-size: 14px !important;
  }
  .textlabel_viewsschedule {
    font-size: 13px !important;
  }
  .view_text {
    font-size: 12px !important;
  }
  .margin-top-minus {
    margin-top: -35px !important;
  }
}

@media (max-width: 959px) {
  .course-highlight-image {
    width: 45px !important;
    height: 45px !important;
  }

  .margin-top-minus {
    margin-top: 15px !important;
  }
  .view_subscription {
    width: 60%;
    height: 40px;
    margin-left: 60px !important;
  }

  /* .course-highlight-header {
      font-size: 18px !important;
    }

    .course-highlight-text {
      font-size: 14px !important;
    } */
  .feature_img {
    height: 60px;
    /* width: 60px; */
    padding: 30px 0 20px 0;
  }

  .subject_image {
    height: 60px !important;
    width: 60px !important;
  }

  .headerImageLogo {
    height: 40px !important;
    width: 80px !important;
  }

  .quote-image {
    height: 50px !important;
    width: 50px !important;
  }
  .Change-Btn {
    margin: 0 10px !important;
  }

  .Free-Trial-Course-Btn {
    width: 95px;
    height: 35px;
    margin-right: 5px !important;
  }

  .Free-Trial-Course-Text {
    font-size: 14px;
  }

  .Login-Course-Btn {
    width: 95px;
    height: 35px;
    margin: 0 !important;
  }

  .Login-Course-Text {
    font-size: 14px;
  }
  .Course-Details-Title {
    max-width: 170px;
  }
  .textlabel_heading {
    font-size: 28px !important;
  }
  .textlabel_heading_course {
    font-size: 28px !important;
  }
  .our-repeater-course-text {
    font-size: 14px !important;
  }
  .learn-more-text {
    font-size: 14px !important;
  }
  .enroll-now-small-text {
    font-size: 14px !important;
  }

  .textlabel_batches {
    font-size: 28px !important;
    margin-top: 20px !important;
  }
  .get_assi_text {
    font-size: 20px !important;
  }
  .course-highlight-header {
    font-size: 17px !important;
  }
  .course-highlight-text {
    font-size: 14px !important;
  }
  .textlabel_personal {
    font-size: 18px !important;
  }
  .textlabel_details {
    font-size: 14px !important;
  }
  .textlabel_features {
    font-size: 16px !important;
  }
  .textlabel_selected {
    font-size: 14px !important;
  }
  .textlabel_notselected {
    font-size: 14px !important;
  }
  .textlabel_live {
    font-size: 14px !important;
  }
  .textlabel_discount {
    font-size: 14px !important;
  }
  .textlabel_price {
    font-size: 14px !important;
  }
  .textlabel_buyfullcourse {
    font-size: 14px !important;
  }
  .textlabel_viewall_course {
    font-size: 14px !important;
  }
  .textlabel_subjectname {
    font-size: 14px !important;
  }
  .textlabel_batchname {
    font-size: 20px !important;
    margin: 15px 0 !important;
  }
  .textlabel_startdate {
    font-size: 12px !important;
  }
  .textlabel_language_hindi {
    font-size: 12px !important;
  }
  .textlabel_viewsschedule {
    font-size: 12px !important;
  }
  .Hear-from-our-text {
    font-size: 19px !important;
  }
  .view_name_text {
    font-size: 18px !important;
  }
  .view_parent_text {
    font-size: 16px !important;
  }
  .view_text_desc {
    font-size: 14px !important;
  }
  .view_img {
    width: 60px;
  }
  .faq-below-text {
    font-size: 16px !important;
  }
  .small-chapter-name {
    font-size: 16px !important;
  }
  .view_text {
    font-size: 12px !important;
  }
  .sub_item_view_box {
    padding: 8% 9%;
  }
}

@media (max-width: 599px) {
  .textlabel_batches {
    font-size: 22px !important;
  }
  .get_assi_text {
    font-size: 16px !important;
  }
  .quote-image {
    height: 30px !important;
    width: 30px !important;
  }
  .width-70per {
    width: 90%;
  }
  .view_subscription {
    width: 60%;
    height: 40px;
    margin-left: 60px !important;
  }
  .headerImageLogo {
    height: 25px !important;
    width: 50px !important;
  }
  .Course-Details-Title {
    font-size: 14px !important;
  }

  .course-highlight-image {
    width: 30px !important;
    height: 30px !important;
  }

  .imgTick {
    height: 18px;
    width: 22px;
  }
  .Change-Btn {
    width: 60px;
    margin-left: 2px !important;
  }
  .Change-Text {
    font-size: 12px;
  }
  .Course-Details-Title {
    max-width: 120px;
  }
  .Free-Trial-Course-Btn {
    width: 66px;
  }

  .Free-Trial-Course-Text {
    font-size: 12px;
  }

  .Login-Course-Btn {
    width: 60px;
  }

  .Login-Course-Text {
    font-size: 12px;
  }
  .feature_desc {
    font-size: 12px !important;
  }
  .Hear-from-our-text {
    font-size: 17px !important;
    padding-bottom: 10px;
    margin-left: 8px !important;
    margin-top: -10% !important;
  }
  .course-highlight-header {
    font-size: 13px !important;
  }
  .course-highlight-text {
    font-size: 12px !important;
  }
  .textlabel_heading {
    font-size: 26px !important;
  }
  .textlabel_heading_course {
    font-size: 26px !important;
  }
  .view_viewallbox_course {
    height: 30px !important;
  }
  .textlabel_viewall_course {
    font-size: 12px !important;
  }
  .feature_title_course {
    font-size: 16px !important;
  }
  .textlabel_knowmore {
    font-size: 14px !important;
  }
  .small-screen-less-padding {
    padding: 5px !important;
  }
  .textlabel_personal {
    font-size: 14px !important;
  }
  .textlabel_details {
    font-size: 12px !important;
  }
  .view_text {
    font-size: 14px !important;
  }
  .sub_item_view_box {
    padding: 14% 10%;
  }
}

@media (max-width: 399px) {
  .Course-Details-Title {
    max-width: 70px;
  }
  /* .Hear-from-our-text {
      font-size: 13px !important;
    } */
  .textlabel_personal {
    font-size: 14px !important;
  }
  .view_subscription {
    width: 60%;
    height: 40px;
    margin-left: 60px !important;
  }
  .course-highlight-header {
    font-size: 13px !important;
  }
  .sub_item_view_box {
    padding: 20% 10%;
  }
}

.img_feedback_main {
    width: 59px;
    height: 59px;
    margin: 0 40px 0 0;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .Performance_Analysis_text {
    font-family: Inter !important;
    font-size: 32px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53 !important;
    color: #394052;
    display: flex;
    align-items: center;
  }

.report_image {
    width: 84px;
    height: 84px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .report_title {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
    color: #394052;
    margin: 4% 15% 2% 15% !important;
  }

  .report_desc {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: normal !important;
    text-align: center;
    color: #394052;
    margin: 0 5% !important;
  }

  .horizonal-line {
    margin: 3% 0;
    border: solid 1px #bac6e4;
  }

  .Enroll-Now-button {
    width: 228px;
    height: 60px;
    padding: 1% 3% !important;
    border-radius: 5px !important;
    background-color: #5580ea !important;
    transform: skewX(-6deg);
    margin: 3% 0 0 3% !important;
  }

  .Enroll-Now-btn-text {
    font-family: Inter !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    text-align: center !important;
    color: #fff;
    text-transform: capitalize;
    transform: skewX(1deg);
  }

@media (max-width: 1279px) {
  .Performance_Analysis_text {
    font-size: 30px !important;
  }
  .report_image {
    width: 60px;
    height: 60px;
  }
  .report_title {
    font-size: 18px !important;
    margin: 4% 0 2% 0 !important;
  }
  .report_desc {
    font-size: 14px !important;
    margin: 0 !important;
  }
  .performance_report_modal {
    width: 85% !important;
  }
  .fix-spacing {
    width: 100% !important;
    margin: 0 !important;
  }
}

@media (max-width: 959px) {
  .img_feedback_main {
    width: 55px;
    height: 55px;
    margin: 0 20px 0 0;
  }
  .Performance_Analysis_text {
    font-size: 26px !important;
  }
  .report_image {
    width: 50px;
    height: 50px;
  }
  .report_title {
    font-size: 16px !important;
  }
  .report_desc {
    font-size: 13px !important;
  }
  .Enroll-Now-button {
    width: 210px;
    height: 50px;
  }
  .Enroll-Now-btn-text   {
    font-size: 20px !important;
  }
}

@media (max-width: 599px) {
  .img_feedback_main {
    width: 40px;
    height: 40px;
    margin: 0 8px 0 0;
  }

  .Performance_Analysis_text {
    font-size: 20px !important;
  }

  .report_image {
    width: 45px;
    height: 45px;
  }

  .report_title {
    font-size: 16px !important;
    margin: 4% 0 2% 0 !important;
  }

  .report_desc {
    font-size: 12px !important;
  }

  .Enroll-Now-button {
    width: 200px;
    height: 50px;
    padding: 1% 3% !important;
    margin: 3% 0 0 3% !important;
  }

  .Enroll-Now-btn-text {
    font-size: 18px !important;
  }
  .top-spacing-small-screen {
    padding-top: 5% !important;
  }
  .bottom-spacing-small-screen {
    padding-bottom: 10% !important;
  }
  .center-content {
    display: flex;
    place-content: center;
  }
 
}
.textlabel_batch_heading {
  font-family: Inter !important;
  font-size: 2.8vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46 !important;
  color: #394052;
}
/* .Hear-from-our-text {
  font-family: Inter !important;
  font-size: 2.6vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
  padding-left: 45px;
  margin-left: -25px !important;
  width: 600px;
} */


.language_view_box {
  width: 70px;
  height: 30px;
  margin: 0 13px 0 0;
  padding: 5px 13px;
  border-radius: 4px;
  background-color: #e1e9fb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language_hindi {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #233c79;
}

.bitmap-video-img {
  width: 100%;
  /* height: 310px; */
  -o-object-fit: contain;
     object-fit: contain;
}

.overlap_view_box {
  /* width: 1170px;
    height: 164px;
    margin: 100px 0.4px 854px 2px;
    padding: 49px 61px 48px 55px; */
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  margin-top: -70px !important;
}

.img-event-1 {
  width: 67px;
  height: 67px;
  margin: 0 25px 0 0;
  -o-object-fit: contain;
     object-fit: contain;
}

.morning-batch-text {
  margin: 0 0 11px;
  font-family: Inter !important;
  font-size: 1.8vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #464c5d;
}

.view-schedule-text {
  margin: 11px 0px 0 0 !important;
  font-family: Inter !important;
  font-size: 1.2vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #5580ea;
  text-decoration: underline;
}

.subjects {
  padding: 6% 13% !important;
  margin-bottom: 10% !important;
  border-radius: 5px !important;
}

.slick-slider.teach-you .slick-next {
  top: -30px !important;
  height: 30px;
  width: 34px;
}

.slick-slider.teach-you .slick-prev {
  top: -30px !important;
  right: 15px !important;
  left: auto !important;
  height: 30px;
  width: 34px;
}

  .slick-slider.teach-you .slick-prev{
    top: -30px !important;
    right: 15px !important;
    left: auto !important;
    height: 30px;
    width: 34px;
  }

  .Educator-Profile {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
  }

  .Educator-bg-block {
    border-radius: 15px;
    background-color: #f8f9ff;
    padding: 5%;
  }

  .Educator-name {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: bold !important;
    color: #394052;
  }

  .Educator-exp {
    font-family: Inter !important;
    font-size: 14px !important;
    color: #394052;
  }

  .Educator-years-experience-text {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64 !important;
    color: #394052;
  }

  .plus-Likes {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #394052;
  }

  .experience-text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
    margin-left: 5% !important;
  }

  .experience-text-dynamic {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
  }

  .Years-of-Teaching-Text {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #394052;
    margin-left: 3% !important;
  }

  .achievements-text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
    margin-left: 2.2% !important;
  }

  .Best-Teacher-Award-Text {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #394052;
    margin-left: 1.5% !important;
  }

  .label-subjectname {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #394052;
    padding: 5% 0;
  }

  .Testimonials-text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #394052;
  }

  .Gamaho-Gemoy-Text {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #000;
  }

  .Student-Text {
    font-family: Inter !important;
    font-size: 14px !important;
    color: #677788;
    margin-top: 10px !important;
  }

  .Student-Desc-Text {
    font-family: Inter !important;
    font-size: 16px !important;
    color: #000;
    line-height: 1.88 !important;
    margin-top: 18px !important;
  }

  .close-rectangle {
    width: 33px;
    height: 33px;
    border-radius: 3px;
    background-color: #5580ea;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view_box_subject_educator {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    padding: 10% 5px;
    width: 90%;
  }

  .selectedImage {
    height: 80px;
    width: 80px;
    opacity: 1;
    margin-left: 15px;
  }

  .notSelectedImage {
    height: 50px;
    width: 50px;
    opacity: 0.53;
    margin-left: 15px;
  }

  .listitem_subjectlogo {
    height: 40px;
    width: 40px;
  }

  .listitem_subjectname {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #394052;
    margin-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .react-player > video {
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
    
  }
  /* .react-player{
    width: 600px!important;
    height: 300px!important;
    margin-top: 35px;
  } */

  .schedule-bg-block {
    border-radius: 15px;
    background-color: #f8f9ff;
    padding: 2% 3%;
  }

  .schedule-title {
    font-family: Inter !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal ;
    line-height: 1.45 !important;
    /* text-align: justify; */
    color: #394052;
  }

  .schedule_startdate {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06 !important;
    color: #394052;
  }

  .schedule-date-header {
    font-family: Inter !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86 !important;
    color: #394052;
  }

  .lecture-bg-box {
    border-radius: 5px;
    box-shadow: 0 1px 15px 3px rgba(230, 240, 254, 0.48);
    background-color: #fff;
    padding: 3% 5%;
    margin: 3% 0;
  }

  .lecture-title {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
  }

  .unit-and-time-text {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #5c76b6;
  }

  .lecturer-image {
    margin-right: 11px;
  }

.lecturer-name {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
}

.selected_date_view_box {
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 60%;
}

.selected_date {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  color: #394052;
  margin-left: 15px;
}

.scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot {
  min-width: inherit;
  overflow: auto;
  box-shadow: 0 34px 24px -9px #edf3ff;
}

.scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot
  .MuiPickersBasePicker-pickerView {
  max-width: inherit;
}

.scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot
  .MuiPickersDay-daySelected {
  background-color: #5580ea;
  border-radius: 8px;
}

.scheduleCalendar .MuiPickersCalendar-week {
  justify-content: space-between !important;
}
.scheduleCalendar .MuiPickersCalendarHeader-daysHeader {
  justify-content: space-between !important;
}
.scheduleCalendar .MuiPickersCalendarHeader-dayLabel{
  color: #394052 !important;
  font-family: Inter !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.scheduleCalendar .MuiPickersCalendarHeader-transitionContainer {
  color: #3f526d;
}
.scheduleCalendar .MuiPickersSlideTransition-transitionContainer > * {
  font-size: 16px;
  font-weight: bold;
}
.scheduleCalendar .MuiPickersDay-hidden {
  opacity: 0.5;
}
.scheduleCalendar .MuiPickersBasePicker-pickerView {
  min-width: 280px;
  width: 100%;
}
.list-style {
  overflow: auto;
  max-height: 60vh;
}
.scheduleCalendar {
  padding-right: 10%;
}
.scheduleCalendar .MuiPickersCalendarHeader-switchHeader {
  border-bottom: 0.5px solid lightgray;
}
.chapter-dynamic-width {
  width: 35%;
}

  @media (max-width: 1279px) {
    .textlabel_batch_heading {
      font-size: 38px !important;
    }
    .morning-batch-text {
      font-size: 22px !important;
    }
    .img-event-1 {
      height: 50px;
      width: 50px;
      margin: 0 10px 0 0;
    }
    .view-schedule-text {
      font-size: 14px !important;
    }
    .Educator-image{
      width: 90%;
    }
    .Years-of-Teaching-Text {
      font-size: 15px !important;
    }
    .Best-Teacher-Award-Text {
      font-size: 15px !important;
    }
    .listitem_subjectname {
      margin-left: 0;
    } 

    .batch-teacher-small {
      width: 100%;
    }
    .teacher-block {
      width: 100% !important;
      height: -webkit-fit-content !important;
      height: -moz-fit-content !important;
      height: fit-content !important;
    }
    .MuiListItemAvatar-root {
      min-width: 45px !important;
    }
    .small-list-item {
      font-size: 14px !important;
    }
    .schedule-date-header {
      font-size: 20px !important;
    }
    .lecture-title {
      font-size: 16px !important;
    }
    .lecturer-name {
      font-size: 16px !important;
    }
    .chapter-dynamic-width {
      width: 50%;
    }
  }

  @media (max-width: 959px){
    .textlabel_batch_heading {
      font-size: 32px !important;
    }
    .morning-batch-text {
      font-size: 18px !important;
    }
    .batch_enroll_now_btn{
      width: 180px !important;
      height: 50px !important;
      margin-bottom: 20px !important;
    }
    .batch_enroll_now_btn_text{
      font-size: 18px !important;
    }
    .view-schedule-text {
      font-size: 12px !important;
    }
    .like-small-button {
      height: 40px !important;
      width: 110px !important;
      margin-left: 4% !important;
    }
    .listitem_subjectlogo {
      height: 36px;
      width: 36px;
    }
    .MuiListItemAvatar-root {
      min-width: 40px !important;
    }
    .batch-teacher-small {
      width: 75%;
    }
    .list-style {
      max-height: 30vh;
    }
    .schedule_startdate {
      font-size: 14px !important;
    }
    .scheduleCalendar {
      padding-right: 0;
      padding-bottom: 10px;
    }
    .selected_date_view_box {
     margin-bottom: 5px;
    }
    .scheduleCalendar .MuiPickersStaticWrapper-staticWrapperRoot {
      box-shadow: none;
    }
    .chapter-dynamic-width {
      width: 70%;
    }
  }

  @media (max-width: 599px){
    .textlabel_batch_heading {
      font-size: 28px !important;
    }
    .morning-batch-text {
      font-size: 16px !important;
    }
    .img-event-1 {
      height: 25px;
      width: 25px;
      margin: 0 5px 0 0;
    }
    .view-schedule-text {
      font-size: 12px !important;
    }
    .spacing-60{
      padding: 30px 0;
    }
    .batch_enroll_now_btn{
      width: 150px !important;
      height: 50px !important;
      margin-bottom: 20px !important;
    }
    .batch_enroll_now_btn_text{
      font-size: 16px !important;
    }
    .Educator-years-experience-text {
      font-size: 12px !important;
    }
    .plus-Likes {
      font-size: 14px !important;
    }
    .experience-text {
      font-size: 14px !important;
    }
    .experience-text-dynamic {
      font-size: 14px !important;
    }
    .Years-of-Teaching-Text {
      font-size: 12px !important;
    }
    .Best-Teacher-Award-Text {
      font-size: 12px !important;
    }
    .achievements-text {
      font-size: 18px !important;
    }
    .Testimonials-text {
      font-size: 18px !important;
    }
    .Gamaho-Gemoy-Text {
      font-size: 18px !important;
    }
    .small-image {
      height: 20px;
      width: 20px;
    }
    .Student-Desc-Text { 
      font-size: 14px !important;
    }

    .selectedImage {
      height: 40px;
      width: 40px;
      margin-left: 5px;
    }
  
    .notSelectedImage {
      height: 30px;
      width: 30px;
      margin-left: 5px;
    }

    .like-small-button {
      height: 35px !important;
      width: 85px !important;
    }
   
    .slick-slider.teach-you .slick-next{
      height: 24px;
      width: 26px;
      right: -5px !important;
    }
  
    .slick-slider.teach-you .slick-prev{
      right: 30px !important;
      height: 24px;
      width: 26px;
    }
    .batch-teacher-small {
      width: 100%;
    }
    .Educator-image {
      margin-bottom: 3% !important;
    }
    .scheduleCalendar {
      padding-right: 0;
      padding-bottom: 10px;
    }
    .small-screen-justify-start {
      justify-content: flex-start !important;
    }
    .schedule-title {
      font-size: 16px !important;
    }
    .schedule_startdate {
      font-size: 14px !important;
    }
    .selected_date_view_box {
      width: 90%;
      padding: 10px;
      margin: 5px 0;
    }
    .schedule-date-header {
      font-size: 18px !important;
    }
    .lecture-title {
      font-size: 14px !important;
    }
    .lecturer-name {
      font-size: 14px !important;
    }
    .lecturer-image {
      margin-right: 3px;
      height: 30px;
    }
    .unit-and-time-text {
      font-size: 12px !important;
    }
   .schedule-modal {
     overflow: auto;
     height: 90%;
     width: auto !important;
   }
 
    .overlap_view_box{
      margin-top: 0px !important;
    }
    .label-subjectname {
      font-size: 12px !important;
    }
    .small-chapter-name {
      font-size: 14px !important;
    }
    .small-topic-style {
      font-size: 12px !important;
      margin-left: 2% !important;
    }
    .font-14 {
      font-size: 14px !important;
    }
    .font-12 {
      font-size: 12px !important;
    }
  }

.policy-bg-mask {
    background-color: #5580ea;
    padding: 5% 0 3% 0;
  }

  .policy_textlabel_heading {
    font-family: Inter !important;
    font-size: 3.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46 !important;
    color: #fff;
  }  

  .Last-modified-text {
    font-family: Inter !important;
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78 !important;
    color: #fff;
  }

  .privacy_policy_view_box {
    padding: 4% 7%;
    border-radius: 10px;
    box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
    background-color: #fff;
    margin-bottom: 27px;
  }

  .view_box_header_text {
    font-family: Inter !important;
    font-size: 2.3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #394052;
    padding-bottom: 3%;
  }

  .view_box_content_text {
    font-family: Inter !important;
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67 !important;
    text-align: justify;
    color: #394052;
  }

  .view_box_content_text_2 {
    font-family: Inter !important;
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67 !important;
    text-align: justify;
    color: #394052;
    padding-top: 3%;
  }

  @media (max-width: 1279px){
    .policy-image {
      width: 100%
    }
    .policy_textlabel_heading {
      font-size: 36px !important;
    }
    .Last-modified-text {
      font-size: 18px !important;
    }
    .view_box_header_text {
      font-size: 28px !important;
    }
    .view_box_content_text {
      font-size: 16px !important;
    }
    .view_box_content_text_2 {
      font-size: 16px !important;
    }
  } 
  
  @media (max-width: 959px){
    .policy_textlabel_heading {
      font-size: 32px !important;
    }
    .Last-modified-text {
      font-size: 16px !important;
    }
    .view_box_header_text {
      font-size: 24px !important;
    }
  }
  
  @media (max-width: 599px){
    .policy-image {
      /* width: 60%; */
      padding: 10px 0;
    }
    .small-screen-justify-center {
      justify-content: center !important;
    }
    .policy_textlabel_heading {
      font-size: 26px !important;
    }
    .Last-modified-text {
      font-size: 14px !important;
    }
    .view_box_header_text {
      font-size: 18px !important;
    }
    .view_box_content_text {
      font-size: 12px !important;
    }
    .view_box_content_text_2 {
      font-size: 12px !important;
    }
    .small-screen-centered {
      padding-left: 0 !important;
      text-align: center;
    }
    .privacy_policy_view_box {
      margin-bottom: 20px;
    }
  }

.Empowering-Students-text {
    font-family: Inter !important;
    font-size: 2.6vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26 !important;
    color: #394052;
    margin-bottom: 25px !important;
  }

  .Own-the-unlimited-text {
    font-family: Inter !important;
    font-size: 1.5vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #677788;
  }

  .TECHNOLOGY-text {
    font-family: Inter !important;
    font-size: 1.45vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29 !important; 
    text-align: center;
    color: #5580ea;
  }

  .Our-Learning-Manage-text {
    font-family: Inter !important;
    font-size: 2.6vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.26 !important;
    text-align: center;
    color: #394052;
    max-width: 60%;
  }

  .padding-top-bottom {
    padding: 5% 0;
  }

  .left-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 3.5%;
}

  .right-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 3.5%;
  }

.block-img {
  width: 100%;
  /* height: 314px; */
  -o-object-fit: contain;
     object-fit: contain;
}

  .block-text-1 {
    font-family: Inter !important;
    font-size: 3vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
    margin-bottom: 16px !important;
  }

  .block-text-2 {
    font-family: Inter !important; 
    font-size: 1.25vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56 !important;
    color: #394052;
  }

  .sub-block-rectangle {
    padding: 5% 7%;
    border-radius: 4px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    margin: 10px 0;
  }

  .sub-block-header-text {
    font-family: Inter !important;
    font-size: 2vw !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    color: #394052;
  }

  .sub-block-content-text {
    font-family: Inter !important;
    font-size: 1.12vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75 !important;
    color: #394052;
    margin-top: 15px !important;
  }

  .technology-main-block-style {
    padding-right: 15%;
  }

  .react-player__preview {
    border-radius: 10px;
  }

/* Remove extra css */

.Competitive-container-info{
  display: flex;
  background: #f8f9ff;
  justify-content: center;
}
.technology-main-block-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.main-block-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0;
  
}


.justifyContent-center {
  display: flex;
  justify-content: center;
}

  @media (max-width: 1279px) {
    .Empowering-Students-text {
      font-size: 34px !important;
    }    
    .Own-the-unlimited-text {
      font-size: 20px !important;
    }
    .TECHNOLOGY-text {
      font-size: 21px !important;
    }
    .Our-Learning-Manage-text {
      font-size: 34px !important;
      max-width: 70%;
    }
    .block-text-1 {
      font-size: 34px !important;
    }
    .block-text-2 {
      font-size: 18px !important;
    }
    .sub-block-header-text {
      font-size: 24px !important;
    }
    .sub-block-content-text {
      font-size: 16px !important;
    }
    .technology-main-block-style {
      padding-right: 10%;
    }
  }

  @media (max-width: 959px) {
    .Empowering-Students-text {
      font-size: 30px !important;
      margin-bottom: 15px !important;
    }    
    .Own-the-unlimited-text {
      font-size: 16px !important;
      margin-bottom: 15px !important;
    }
    .TECHNOLOGY-text {
      font-size: 20px !important;
    }
    .Our-Learning-Manage-text {
      font-size: 30px !important;
      max-width: 90%;
    }
    .block-text-1 {
      font-size: 26px !important;
    }
    .block-text-2 {
      font-size: 14px !important;
    }
    .sub-block-header-text {
      font-size: 20px !important;
    }
    .sub-block-content-text {
      font-size: 14px !important;
    }
    /* .video-display-size {
      height: 100% !important;
    } */
  }

  @media (max-width: 599px) {
    .Empowering-Students-text {
        font-size: 28px !important;
        margin-bottom: 10px !important;
    }    
    .Own-the-unlimited-text {
      font-size: 14px !important;
      margin-bottom: 10px !important;
    }
    .TECHNOLOGY-text {
      font-size: 16px !important;
    }
    .Our-Learning-Manage-text {
      font-size: 26px !important;
      max-width: 95%;
    }
    .block-text-1 {
      font-size: 24px !important;
    }
    .block-text-2 {
      font-size: 14px !important;
    }
    .sub-block-header-text {
      font-size: 16px !important;
    }
    .sub-block-content-text {
      font-size: 12px !important;
    }
    .left-block {
      padding: 5% 0;
    }
    .right-block {
      padding: 5% 0;
    }
    .technology-main-block-style {
      padding-right: 0;
      text-align: center;
    }
    .padding-top-bottom {
      padding: 0;
    }
    .video-display-size {
      height: 250px !important;
    }
  }


.crew_view_box {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 8% 10%;
}

.crew_view_box_header {
  font-family: Inter !important;
  font-size: 1.68vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
}

.crew_view_box_details {
  font-family: Inter !important;
  font-size: 1.26vw !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #fff;
  margin-top: 12px !important;
}

.faculty-card {
    padding: 20px 10px;
    border-radius: 3px;
    box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
    background-color: #fff;
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: calc(100% - 32px);
  }

  .faculty-profile-img {
    width: 162px;
    height: 160px;
    padding-bottom: 20px;
  }

  .faculty-profile-avatar {
    border-radius: 50% !important;
    height: 162px !important;
    width: 162px !important;
    margin-bottom: 20px;
  }

  .faculty-profile-detail-avatar {
    border-radius: 10% !important;
    height: 162px !important;
    width: 162px !important;
  }

  .faculty-name {
    font-family: Inter !important;
    font-size: 1.3vw !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #1b2437;
    height: 60px;
  }

  .faculty-role-text {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #98a0a6;
  }

  .img-location {
    width: 13px;
    height: 14px;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 7px;
  }

  .faculty-location-text  {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #98a0a6;
    padding:20px;
    height: 110px;
  }

  .view-details-btn {
    width: 190px;
    height: 60px;
    border-radius: 5px !important;
    background-color: #5580ea !important;
    text-transform: capitalize !important;
    margin-top: 5% !important;
    transform: skewX(-6deg);
  }

  .view-details-text {
    font-family: Inter !important;
    font-size: 1.15vw !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    text-align: center;
    color: #fff;
    transform: skewX(1deg);
  }

  .display-style {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      margin-bottom: 2% !important;
  }

  .layer {
    position: absolute;
    background-color: #5580ea;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    border-radius: 3px;
    opacity: 0.94;
  }

  .faculty-desc-text {
    padding: 3% 5% !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important; 
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4 !important;
    text-align: center;
    color: #fff;
    align-items: center;
    overflow: auto;
  }

.small-screen-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;
}

.small-screen-justify-center{
  display: flex;
  justify-content: flex-end;
}

.leadership-crew{
  display: flex;
  justify-content: center;
  background: #f8f9ff;
  padding-bottom: 3%;
}

.direction-row {
  display: flex;
  flex-direction: row;
}

.m5 {
  margin: 5% 0;
}

.align-items-center{
  display: flex;
  align-items: center;
}

@media (max-width: 1279px) {
  .crew_view_box_header {
    font-size: 20px !important;
  }
  .crew_view_box_details {
    font-size: 16px !important;
  }
  .faculty-name {
    font-size: 20px !important;
  }
  .faculty-profile-img {
    width: 116px;
    height: 115px;
    padding-bottom: 15px;
  }
  .faculty-profile-avatar {
    height: 115px !important;
    width: 115px !important;
    margin-bottom: 15px;
  }
  .faculty-profile-detail-avatar {
    height: 115px !important;
    width: 115px !important;
  }
  .view-details-btn {
    width: 160px;
    height: 50px;
  }
  .view-details-text {
    font-size: 18px !important;
  }
  .faculty-desc-text {
    font-size: 12px !important;
  }
  .faculty-location-text {
    height: 150px;
  }

}

@media (max-width: 959px) {
  .crew_view_box_header {
    font-size: 16px !important;
  }
  .crew_view_box_details {
    font-size: 14px !important;
  }
  .faculty-location-text {
    height: 120px;
  }
}

@media (max-width: 599px) {
  .crew_view_box_header {
    font-size: 14px !important;
  }
  .crew_view_box_details {
    font-size: 12px !important;
  }
  .faculty-location-text {
    height: auto;
  }
  .small-screen-pb {
    padding-bottom: 5% !important;
  }
}
.textlabel_about_us_heading {
  font-family: Inter !important;
  font-size: 3.3vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43 !important;
  color: #394052;
}

.about_us_heading_underline {
  border-bottom: 13px solid #ffebc1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  line-height: 0.5;
}

.img_work_hard {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -o-object-fit: contain;
     object-fit: contain;
}

.features_view_box {
  padding: 5%;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  margin-top: -4%;
  margin-bottom: 5%;
}

.If-a-sporting-activity-text {
  font-family: Inter !important;
  font-size: 2vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal !important;
  text-align: center;
  color: #394052;
}

.feature_image_source {
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
}

.feature_title_text {
  font-family: Inter !important;
  font-size: 1.5vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41 !important;
  text-align: center;
  color: #394052;
  margin: 4% 11% !important;
}

.feature_desc_text {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81 !important;
  text-align: center;
  color: #394052;
}

.For-item-selected-title {
  font-family: Inter !important;
  font-size: 2.2vw !important;
  font-weight: bold !important;
  color: #394052;
  padding: 5% 0;
  cursor: pointer;
}

.For-item-not-selected-title {
  font-family: Inter !important;
  font-size: 1.85vw !important;
  font-weight: bold !important;
  color: #9dadbe;
  padding: 5% 0;
  cursor: pointer;
}

.selected-line {
  padding-bottom: 15px;
  border-bottom: 3px solid #5580ea;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
}

.not-selected-line {
  padding-bottom: 15px;
  border-bottom: 3px solid #cad9e9;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
}

.for_student_view_box {
  padding: 3% !important;
  border-radius: 10px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  height: 90px;
  display: flex;
  align-items: center;
}

.for_student_title {
  font-family: Inter !important;
  font-size: 1.85vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #3f526d;
  margin-bottom: 2% !important;
  padding-left: 16px;
}

.for_student_details {
  font-family: Inter !important;
  font-size: 1.39vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4 !important;
  color: #394052;
  margin-left: 3% !important;
}

.best-office-employee-img {
  width: 100%;
  /* height: 460px; */
  -o-object-fit: contain;
     object-fit: contain;
}

.textlabel_values_ethos {
  margin: 0 0 34px 0 !important;
  font-family: Inter !important;
  font-size: 2.65vw !important;
  font-weight: bold !important;
  line-height: 1.34 !important;
  color: #394052;
}

.textlabel_values_ethos_desc {
  font-family: Inter !important;
  font-size: 1.4vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9 !important;
  color: #394052;
}

.Our_values_rectangle {
  padding: 5%;
  border-radius: 16px;
  box-shadow: 0 12px 48px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
  position: relative;
}

.Our_values_textlabel {
  font-family: Inter !important;
  font-size: 2.65vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92 !important;
  color: #394052;
}

.With_the_student_text {
  margin: 10px 0 0;
  font-family: Inter !important;
  font-size: 1.7vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64 !important;
  color: #394052;
}

.tiltSliderDots {
  padding: 10px 0 0px 0;
}

.slick-slider.tiltSliderDots .slick-dots {
  justify-content: flex-end;
  position: absolute !important;
  height: 20px;
  top: -35px;
}
.slick-slider.tiltSliderDots .slick-dots li {
  width: 22.9px;
  height: 20px;
  margin: 0 8px;
}
.slick-slider.tiltSliderDots .slick-dots li button {
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}
.slick-slider.tiltSliderDots .slick-dots li::after {
  width: 22.9px;
  height: 20px;
  border-radius: 3px;
  transform: skewX(-6deg);
}

.slick-slider.tiltSliderDots .slick-dots li.slick-active::after {
  width: 22.9px;
  height: 20px;
  border-radius: 3px;
  transform: skewX(-6deg);
}

.slick-slider.about-carousel .slick-next {
  top: -20px !important;
  height: 30px;
  right: 3% !important;
  width: 34px;
}

.slick-slider.about-carousel .slick-prev {
  top: -20px !important;
  right: 8% !important;
  left: auto !important;
  height: 30px;
  width: 34px;
}

.pause_icon {
  width: 33px;
  height: 39px;
  font-family: Inter !important;
  font-size: 92px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.87 !important;
  color: #5580ea;
  position: absolute;
  top: -5%;
  left: 5%;
}

.leadership_team_textlabel {
  margin: 0 0 1% 0 !important;
  font-family: Inter !important;
  font-size: 2.65vw !important;
  font-weight: bold !important;
  line-height: 1.92 !important;
  color: #394052;
}

.leadership_team_details {
  margin: 0 0 2% 0 !important;
  font-family: Inter !important;
  font-size: 1.5vw !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5 !important;
  color: #394052;
}

.leader_rectangle {
  padding: 20px 10px;
  border-radius: 3px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  background-color: #fff;
  width: 59%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 8%;
}

.leader_image {
  width: 162px;
  height: 185px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: -10%;
}

.leader_name {
  font-family: Inter !important;
  font-size: 1.5vw !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #394052;
  margin-bottom: 2% !important;
  margin-top: 4% !important;
  text-align: center;
}

.leader_details {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  height: 120px;
  overflow: auto;
}

.leader_less_details {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  text-align: center;
  color: #394052;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.leader_know_more {
  font-family: Inter !important;
  font-size: 1.4vw !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  color: #5580ea;
  padding-top: 10px;
}

.leader-profile-avatar {
  border-radius: 10% !important;
  height: 185px !important;
  width: 162px !important;
  margin-top: -10%;
}

/* Remove inline code */
.banner-container {
  display: flex;
  justify-content: center;
  margin: 2% 0 4% 0;
}

.banner-content-outer {
  padding-left: 15%;
  padding-right: 10%;
  display: flex;
  align-items: center;
}

.study-materials {
  display: flex;
  justify-content: center;
  background: #f8f9ff;
  flex-direction: column;
  padding-bottom: 5%;
  align-items: center;
}
.view_box-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
}

.values-ethos-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pl2 {
  padding-left: 2%;
}

@media (max-width: 1279px) {
  .textlabel_about_us_heading {
    font-size: 40px !important;
  }
  .If-a-sporting-activity-text {
    font-size: 24px !important;
  }
  .feature_title_text {
    font-size: 20px !important;
  }
  .feature_desc_text {
    font-size: 14px !important;
  }
  .For-item-selected-title {
    font-size: 30px !important;
  }
  .For-item-not-selected-title {
    font-size: 24px !important;
  }
  .for_student_title {
    font-size: 20px !important;
  }
  .for_student_details {
    font-size: 16px !important;
  }
  .textlabel_values_ethos {
    font-size: 30px !important;
    margin-bottom: 15px !important;
  }
  .textlabel_values_ethos_desc {
    font-size: 16px !important;
  }
  .Our_values_textlabel {
    font-size: 30px !important;
  }
  .With_the_student_text {
    font-size: 20px !important;
  }
  .leadership_team_textlabel {
    font-size: 30px !important;
  }
  .leadership_team_details {
    font-size: 18px !important;
  }
  .leader_image {
    width: 140px;
    height: 165px;
  }
  .leader-profile-avatar {
    width: 140px;
    height: 165px;
  }
  .leader_name {
    font-size: 22px !important;
  }
  .leader_details {
    font-size: 16px !important;
  }
  .leader_less_details {
    font-size: 16px !important;
  }
  .leader_know_more {
    font-size: 18px !important;
  }
  .about_us_heading_underline {
    border-bottom: 10px solid #ffebc1;
    display: initial;
  }
}

@media (max-width: 959px) {
  .slick-slider.about-carousel .slick-prev {
    right: 15% !important;
  }
  .textlabel_about_us_heading {
    font-size: 31px !important;
  }
  .If-a-sporting-activity-text {
    font-size: 22px !important;
  }
  .leader_details {
    font-size: 14px !important;
  }
  .leader_less_details {
    font-size: 14px !important;
  }
  .textlabel_values_ethos {
    font-size: 28px !important;
  }
  .Our_values_textlabel {
    font-size: 28px !important;
  }
  .leadership_team_textlabel {
    font-size: 28px !important;
  }
  .leader_image {
    width: 110px;
    height: 135px;
  }
  .leader-profile-avatar {
    width: 110px;
    height: 135px;
  }
  .leader_name {
    font-size: 20px !important;
  }
  .For-item-selected-title {
    padding: 3% 0;
  }
  .For-item-not-selected-title {
    padding: 3% 0;
  }
  .centered-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .slick-slider.tiltSliderDots .slick-dots {
    display: flex !important;
  }
}

@media (max-width: 599px) {
  .textlabel_about_us_heading {
    font-size: 30px !important;
  }
  .If-a-sporting-activity-text {
    font-size: 20px !important;
  }
  .feature_image_source {
    height: 50px;
    width: 50px;
  }
  .feature_title_text {
    font-size: 18px !important;
  }
  .feature_desc_text {
    font-size: 14px !important;
  }
  .For-item-selected-title {
    font-size: 26px !important;
  }
  .For-item-not-selected-title {
    font-size: 22px !important;
  }
  .textlabel_values_ethos {
    font-size: 24px !important;
  }
  .Our_values_textlabel {
    font-size: 24px !important;
  }
  .leadership_team_textlabel {
    font-size: 24px !important;
  }
  .selected-line {
    padding-bottom: 10px;
  }

  .not-selected-line {
    padding-bottom: 10px;
  }
  .small-screen-no-space {
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 12% !important;
  }
  .small-screen-leader-spacing {
    margin: 0 !important;
    padding-top: 20% !important;
  }
  .leader_rectangle {
    width: 82%;
  }
  .leader_details {
    height: 120px;
  }
  .With_the_student_text {
    font-size: 16px !important;
  }
  .leadership_team_details {
    font-size: 16px !important;
  }
  .for_student_title {
    font-size: 18px !important;
  }
}

  .container {
    position: relative;
    
  }
  
  
  .topright {
    position: absolute;
    top: .5rem;
    left: 1rem;
    width: 7.0625rem;
  height: 1.9375rem;
  padding: .0625rem .625rem .1875rem .6875rem;
  border-radius: .25rem;
  background-color: #fdb515;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  text-align: right;
  color: #fff;

  }

    .Search-Bg-Rectangle_blog {
        height: 1.8rem;
        padding: .75rem;
        box-shadow: 0 .125rem .25rem 0 #d0d6f9;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: skewX(-6deg);
        margin-top: -1.5625rem;
        margin-bottom: 1.25rem;
      }
      .search-Btn-icon {
        width: 1.5625rem;
        height: 1.875rem;
        margin: 0 0 0 .625rem!important;
        border-radius: .3125rem !important;
        background-color: #5580ea !important;
      }
      .Search-Img-icon {
        width: 1.375rem;
        height: 1.375rem;
        -o-object-fit: contain;
           object-fit: contain;
        color: white;
      }
      .Search-Btn-Blog {
        width: 5rem;
        height: 2.8125rem;
        border-radius: .3125rem !important;
        background-color: #5580ea !important;
        align-self: center;
      }
      .Search-Text-Blog {
        font-family: Inter !important;
        font-size: .875rem !important;
        font-weight: 500 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: normal !important;
        color: #fff;
        text-transform: capitalize;
      }
      .Search-for-course_blog{
        border: none !important;
        font-family: Inter;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.67;
        color: #394052;
        width: 100%;
        height: 1.875rem;
        transform: skewX(1deg);
      }
  
  
    .feature_title {
      font-family: Inter !important;
      font-size: 1.7vw !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal !important;
      text-align: center;
      color: #394052;
      padding-bottom: 1.125rem;
      height: 3.125rem;
    }
  
    .container-8-md{
      border-radius: .125rem;
      box-shadow: 0 0 .8125rem .1875rem rgba(97, 116, 169, 0.05);
      background-color: #fff;
      width: 25rem!important;
      height:37.5rem!important;
      
    }
    .batch-view-box-blog {
      border-radius: 4px;
      box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
      background-color: #fff;
      height: 100%;
      cursor: pointer;
    }
    .batch-view-box-blog-content {
        padding: 0rem 1.25rem 1.25rem 1.25rem;
      }
  
      .textlabel_batches_blog {
        font-family: Inter !important;
        font-size: 1.95vw !important;
        font-weight: bold !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71 !important;
        color: #394052;
      }

  .author_name {
  margin: 0 0 .3812rem !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  color: #000 !important;
  }
  .author {
    margin: .0063rem 0 0 !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    color: #000 !important;
  }
    .textlabel_blog {
      font-family: Inter !important;
      font-size: 1.25vw !important;
      font-weight: bold !important;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.32 !important;
      color: #394052;
      margin: 1.25rem 0 !important;
    }
  
    .textlabel_subtitle_blog {
      font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.93 !important;
  color: #77838f !important;
    }
  
    .view_viewallbox {
      width: 14vw;
      height: 4.2vw;
      border-radius: .3125rem !important;
      background-color: #5580ea !important;
      transform: skewX(-6deg);
    }
  
    .textlabel_viewall {
      font-family: Inter !important;
      font-size: 1.4vw !important;
      font-weight: normal !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal !important;
      text-align: center;
      color: #fff;
      transform: skewX(1deg);
      text-transform: capitalize;
    }
  
  .view_text_desc {
    font-family: Inter !important;
    font-size: 1.5vw !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59 !important;
    color: #000;
  }


.heading-blog {
  display: flex;
  justify-content: center;
  background: f8f9ff;
  padding: 3% 0 6% 0;
}

.pl{
  width: 100%;
  display: flex;
  flex-direction: column;
  
}
.dis-jcenter{
  display: flex;
  justify-content: center;
}

.direction-row {
  display: flex;
  flex-direction: row;
}
.direction-column {
  display: flex;
  flex-direction: column;
}
.image-course {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
  
@media (max-width: 1279px){
  .textlabel_blog {
    font-size: 16px !important;
  }
  .textlabel_subtitle_blog {
    font-size: 12px !important;
  }
  .textlabel_batches_blog {
    font-size: 26px !important;
  }
  .textlabel_viewall {
    font-size: 18px !important;
  }
}

@media (max-width: 959px) {
  .textlabel_blog {
    font-size: 14px !important;
    margin: 12px 0 !important;
  }
  .topright {
    width: 6rem;
  } 
}


@media (max-width: 599px) {
  .textlabel_batches_blog {
    font-size: 24px !important;
    padding-top: 5%;
  }
  .Search-Bg-Rectangle_blog {
    margin-top: -0.5625rem;
  }
  .view_viewallbox {
    height: 35px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px !important;
  }
}

.width-70per-blog {
  margin-left: 135.008px;
}
.firstbox {
  position: "relative";
}
.top-light-blue-box {
  display: flex;
  justify-content: center;
  background-color: #f8f9ff;
  padding: 3% 0 6% 0;
}
.teachze-blog-parent-grid {
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.online-learning-img-blog {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.Search-Bg-Rectangle-blog {
  margin: 10px;
  margin-top: 62px;
  transform: skew(-17deg);
  border-radius: 8px;
  width: 620px;
  height: 57.008px;
  padding: 12px;
  box-shadow: 0 2px 4px 0 #d0d6f9;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Search-Rectangle-blog {
  transform: skew(-1deg);
  border-radius: 5.008px;
  width: 44px;
  height: 44px;
  padding: 14px;
  border-radius: 5.008px;
  background-color: #347bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search-Img-blog {
  width: 22px;
  height: 22px;
  -o-object-fit: contain;
     object-fit: contain;
}

.Search-Btn-blog {
  width: 80px;
  height: 45.008px;
  border-radius: 5.008px !important;
  background-color: #5580ea !important;
  align-self: center;
}

.Search-Text-blog {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  /* letter-spacing: .0838rem !important; */
  color: #fff;
  text-transform: capitalize;
}

.Search-Blogs {
  border: none !important;
  transform: skew(15deg);
  margin-left: 37.008px;
  /* border-bottom: solid .0625rem #bbc4f9 !important; */
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  /* letter-spacing: .04rem; */
  color: #626877;
  width: 200px;
}
.breadkrum {
  margin: 81.008px 100px 17.008px 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  /* letter-spacing: .24px; */
  color: #677788;
}
.imagebelow-text-blog {
  margin-top: 27px;
  font-family: Inter;
  width: 90%;
  font-size: 2.05vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  color: #394052;
}

.image-rep-blog {
  width: 90%;
}
.imageabove-text-blog {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter;
  color: #677788;
}
.listitem_subjectlogooo {
  width: 71px;
  height: 76px;
  -o-object-fit: cover;
     object-fit: cover;
}
.listitem_subjectnameoo {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  color: #394052;
  margin-left: 10px;
}
.subject00 {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
  border-radius: 4px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  align-items: center;
  padding: 5%;
  cursor: pointer;
}
.latest-news {
  font-family: Inter;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  color: #394052;
}
.latest-news-test {
  font-family: Inter;
  font-weight: bolder;
  /* letter-spacing:1px; */
  font-size: 1.6vw;
}
.main-box {
  display: flex;
  width: 100%;
  margin-left: 136px;
  margin-right: 100px;
}
.leftbox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rightbox-start {
  font-family: Inter;
  font-size: 1.39vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  /* text-align: justify; */
  color: #394052;
  padding: 15px 0;
}
.about-author {
  font-family: Inter;
  font-size: 1.39vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #677788;
}
.yellow-button-box {
  align-items: center;
  display: flex;
  font-family: Inter;
  margin-top: 20px;
}
.yellow-button {
  color: white;
  width: 113px;
  height: 31px;
  background-color: #fdb515;
  border-radius: 7px;
  border: 0px solid #fdb515;
  cursor: pointer;
  /* letter-spacing: 1px; */
  font-size: 14px;
}
.para {
  width: 90%;
  margin-top: 30px;
  font-family: Inter;
  font-size: 1.05vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  text-align: justify;
  /* text-align: justify; */
  color: #394052;
}
.domainName {
  text-decoration: none !important;
  color: black !important;
  cursor: pointer;
}

.mb-5 {
  margin-bottom: 20px;
}
.point {
  font-family: Inter;
}
.leave-comment {
  margin-top: 7%;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.name {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
  display: flex;
  flex-direction: row;
}
.name-input {
  width: 100%;
  height: 45px;
  outline: none !important;
  outline-width: 0px;
  border-width: 0.8px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgb(207, 206, 206);
}
.Comment-box {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.comment-box-text {
  width: 100%;
  height: 190px;
  outline: none !important;
  outline-width: 0px;
  border-width: 0.8px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgb(207, 206, 206);
}
.send-button {
  width: 150px;
  height: 50px !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    87deg,
    #f7ba51 -15%,
    #f79f84 118%
  ) !important;

  margin-top: 20px;
  color: white;
  font-family: Inter;
  transform: skew(-6deg);
  box-shadow: 1px 6px 10px #f79f84 !important;
  cursor: pointer;
  font-size: 1.39vw;
}

.email-box-input {
  width: 100%;
  height: 45px;
  outline: none !important;
  outline-width: 0px;
  border-width: 0.8px;
  border-style: solid;
  border-radius: 3px;
  border-color: rgb(207, 206, 206);
}
.email-box {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #394052;
}
.name-email-box {
  display: flex;
  flex-direction: row;
}
.vijay-jala {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
}
.vijay-jala-text {
  font-family: Inter;
  font-size: 1.53vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #000;
}
.vijay-jala-image {
  width: 44px;
  height: 44px;
  -o-object-fit: contain;
     object-fit: contain;
}
.vijay-author {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.vijay-author-text {
  font-family: Inter;
  font-size: 1.39vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #000;
}
.Line {
  color: black;
  background-color: rgb(24, 22, 22);
  align-items: center;
  width: 90%;
  height: 0.1008px;
  margin: 17px 0 20px 0px;
  border: solid 1px #677788;
}
.astrik {
  color: red;
}
.image_background {
  width: 1437px;
  height: 180px;
  margin: 0 0 42px;
  padding: 61px 131px 65px 135px;
  background-color: #5580ea;
  display: flex;
  flex-direction: row;
}

.textlabel_description {
  display: flex;
  margin-left: 70px !important;
  width: 600px !important;
  height: 58px;
  margin: 13px 283px 0 1px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  color: #fff;
}
.view_button {
  text-align: center;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  width: 217px;
  height: 51px;
  margin-right: 20px !important;
  padding-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  transform: skew(-15deg);
}
.textlabel_booktrial {
  width: 176px;
  height: 29px;
  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  color: #5580ea;
  margin-top: 40px;
}
.Rectangle-above-image-background {
  width: 100%;
  margin-top: 5%;
  height: 76px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.31);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.twitter {
  height: 50px;
  width: auto;
  margin-left: 1px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 6px;
}
.Group-2 {
  height: 50px;
  width: auto;
  margin-left: 8px;
  -o-object-fit: contain;
     object-fit: contain;
}
.facebook {
  height: 50px;
  width: auto;
  margin-left: 8px;
  -o-object-fit: contain;
     object-fit: contain;
}
.instagram {
  height: 50px;
  width: auto;
  margin-left: 8px;
  -o-object-fit: contain;
     object-fit: contain;
}
.width_90 {
  width: 90% !important;
}

@media (max-width: 1279px) {
  .imagebelow-text-blog {
    font-size: 28px;
    margin-top: 20px;
  }
  .para {
    font-size: 18px;
    margin-top: 20px;
  }
  .vijay-jala-text {
    font-size: 20px;
  }
  .vijay-author-text {
    font-size: 18px;
  }
  .rightbox-start {
    font-size: 18px;
    text-align: left;
  }
  .listitem_subjectnameoo {
    font-size: 16px;
  }
  .latest-news-test {
    font-size: 22px;
  }
  .about-author {
    font-size: 20px;
  }
  .send-button {
    font-size: 20px;
  }
}

@media (max-width: 959px) {
  .about-author {
    padding-top: 25px;
  }
  .imagebelow-text-blog {
    width: 100%;
    font-size: 26px;
  }
  .image-rep-blog {
    width: 100%;
  }
  .para {
    width: 100%;
    font-size: 16px;
  }
  .Line {
    width: 100%;
  }
  .width_90 {
    width: 100% !important;
  }
  .vijay-jala-text {
    font-size: 18px;
  }
  .vijay-author-text {
    font-size: 16px;
  }
  .rightbox-start {
    font-size: 16px;
  }
  .listitem_subjectnameoo {
    font-size: 14px;
  }
  .leave-comment {
    font-size: 16px;
  }
  .name {
    font-size: 16px;
  }
  .email-box {
    font-size: 16px;
  }
  .Comment-box {
    font-size: 16px;
  }
}

@media (max-width: 599px) {
  .imagebelow-text-blog {
    font-size: 22px;
  }
  .para {
    font-size: 14px;
  }
  .imageabove-text-blog {
    font-size: 14px;
  }
  .leave-comment {
    font-size: 14px;
  }
  .name {
    font-size: 14px;
  }
  .email-box {
    font-size: 14px;
  }
  .Comment-box {
    font-size: 14px;
  }
  .rightbox-start {
    font-size: 14px;
  }
  .latest-news-test {
    font-size: 20px;
  }
  .send-button {
    height: 40px !important;
    width: 125px;
    font-size: 18px;
  }
  .Rectangle-above-image-background {
    justify-content: center;
    padding-right: 0;
  }
}

